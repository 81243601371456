/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import useAppStore from '../contexts/useAppStore';
import Button from './elements/Button';
import SelectInput from './elements/SelectInput';
import { customSelectStyles, filterConfig, getStateName } from '../utils';

function SearchBox() {
    const {
        getStates,
        getSpecialities,
        getDestinations,

    } = useAppStore();

    const [selectedDestination, setSelectedDestination] = useState('');
    const [selectedInterest, setSelectedInterest] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const history = useHistory();

    const label = (title) => <div className="uppercase font-bold text-sm mb-2 hidden sm:block">{title}</div>;

    const onSearch = () => {
        const filters = [];

        if (selectedState !== '') {
            filters.push(`slctState=${selectedState}`);
        }
        if (selectedInterest !== '') {
            filters.push(`slctInterest=${encodeURIComponent(selectedInterest)}`);
        }
        if (selectedDestination !== '') {
            filters.push(`destinationId=${selectedDestination.value}&slctDestination=${selectedDestination.label}`);
        }

        history.push(`/agents?${filters.join('&')}`);
    };

    const groupedSpecialities = () => {
        const items = [];
        getSpecialities().map((x) => {
            if (items.some((y) => y.name === x.category)) {
                items.find((y) => y.name === x.category).items.push({
                    value: x.interest,
                    label: x.interest,
                });
            } else {
                items.push({
                    type: 'group',
                    name: x.category,
                    items: [{
                        value: x.interest,
                        label: x.interest,
                    }],
                });
            }

            return true;
        });

        return items;
    };

    return (
        <div className="bg-white md:shadow-xl rounded px-8 py-6 z-40 text-center">
            <div className="mb-6 text-lg">
                Search for an
                {' '}
                <b>expert travel agent</b>
                {' '}
                in one or more of the following ways.
            </div>
            <div className="flex flex-col md:flex-row gap-4 sm:gap-8 justify-between">

                <div className="flex flex-col justify-center flex-1">
                    {label('LOCATION')}
                    <div>
                        <SelectInput
                            options={[{ label: 'Select a Province', value: '' }].concat(getStates().map((x) => ({
                                value: x.state,
                                label: getStateName(x.state),
                            })))}
                            placeholder="Select a Province"
                            onSelected={(val) => setSelectedState(val)}
                            value={selectedState}
                        />
                    </div>
                </div>

                <div className="flex flex-col justify-center flex-1">
                    <div>
                        {label('SPECIALTY')}
                        <div>
                            <SelectInput
                                options={[{ label: 'Select a Specialty', value: '' }].concat(groupedSpecialities())}
                                placeholder="Select a Specialty"
                                onSelected={(val) => setSelectedInterest(val)}
                                value={selectedInterest}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center flex-1">
                    <div>
                        {label('DESTINATION')}
                        <div>

                            <Select
                                styles={customSelectStyles}
                                className=" w-full rounded-full text-sm"
                                options={[{ label: 'Select a Destination', value: '' }].concat(getDestinations.map((x) => ({
                                    value: x.destinationId,
                                    label: x.destination,
                                })))}
                                onChange={(val) => {
                                    setSelectedDestination(val);
                                    setIsMenuOpen(false);
                                }}
                                onInputChange={(val) => {
                                    if (val !== '') {
                                        setIsMenuOpen(true);
                                    } else setIsMenuOpen(false);
                                }}
                                placeholder="Select a Destination"
                                value={selectedDestination}
                                filterOption={createFilter(filterConfig)}
                                menuIsOpen={isMenuOpen}
                                noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                                components={{ DropdownIndicator: () => null }}

                            />

                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center flex-1">
                    <div className="" />
                    <div className="mt-5">
                        <Button title="SEARCH" onClick={() => onSearch()} />

                    </div>
                </div>
            </div>

        </div>
    );
}

export default SearchBox;
