import React from 'react';
import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';
import Hero from '../components/Hero';
import BreadCrumb from '../components/BreadCrumb';
import { currentDomain } from '../utils';

function AgencyLocator() {
    const links = [{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Agency Locator',
        url: '',
        }];

    return (
        <>
            <Helmet>
                <title>Find a Travel Agency near you  | TL Network</title>
                <meta name="title" content="Find a Travel Agency near you  | TL Network" />
                <meta name="description" content="Locate a TL Network Office in your area via your address, zip or city" />
            </Helmet>
            <Hero title="Locate an Agency" imageFile="hero-map-search.webp" additionalCSS="pt-0 -mb-12" />
            <div className=" max-w-screen-2xl mx-auto">
                <div className="min-h-screen">
                    <div className="mx-4 md:mx-20 pb-10 text-center">
                        <div className="">
                            <BreadCrumb links={links} />
                        </div>
                        <Iframe
                            url="https://www.travelleaders.com/StoreLocator/AgencyLocator.aspx?lcl=CA"
                            width="100%"
                            height="100%"
                            id="agencyMap"
                            className="w-100 agencyLocatorFrame"
                            display="block"
                            position="relative"
                            scrolling="no"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AgencyLocator;
