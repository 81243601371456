/* eslint-disable prefer-destructuring */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import Loading from './elements/Loading';
import useAppStore from '../contexts/useAppStore';
import API from '../services/API';
import ButtonInverted from './elements/ButtonInverted';
import {
    callMe,
    CONTACT_METHOD_EMAIL,
    CONTACT_METHOD_PHONE,
    modalCustomStyles,
} from '../utils';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import 'react-toastify/dist/ReactToastify.css';

function AgentList() {
    const location = useLocation();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const myIp = query.get('ip') || null;
    const [agentData, setAgentData] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState();
    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen,
        isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal,
    } = useAppStore();

    const userLocationCookie = new Cookies();

    const getUserLocation = async () => {
        setLoading(true);
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*GET CLOSEST AGENTS*/
                API.agentMatchToInterest({
                    id: 0,
                    agencyId: 0,
                    destination: 0,
                    interest: 0,
                    supplier: 0,
                    latitude: ipResult.data.latitude,
                    longitude: ipResult.data.longitude,
                    returnAgents: 3,
                    locality: ipResult.data.country_code,
                    promotionId: 0,
                }).then(async (agentResult) => {
                    if (agentResult.responseStatus === 1) {
                        setAgentData(agentResult.data);
                    } else {
                        toast(agentResult.error.message);
                    }
                });
            } else {
                toast(ipResult.error.message);
            }
            setLoading(false);
        });
    };

    const getMatchedAgents = async () => {
        setLoading(true);
            API.agentMatchToInterest({
                id: 0,
                agencyId: 0,
                destination: 0,
                interest: 0,
                supplier: 0,
                latitude: userLocationCookie.get('userLocation').latitude,
                longitude: userLocationCookie.get('userLocation').longitude,
                returnAgents: 3,
                locality: userLocationCookie.get('userLocation').country_code,
                promotionId: 0,
            }).then(async (result) => {
                if (result.responseStatus === 1) {
                    setAgentData(result.data);
                } else {
                    toast(result.error.message);
                }
                setLoading(false);
            });
    };

    /*if cookie is not set, run userlocation and find agents*/
    if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
        useEffect(() => {
            getUserLocation();
        }, []);
    }

/*if cookie is set, use preset cookie data*/
    if ((userLocationCookie.get('userLocation')) && myIp === null) {
        useEffect(() => {
            getMatchedAgents();
        }, []);
    }

    const emailMe = (agent) => {
        setSelectedAgent(agent);
        setIsEmailFormModalOpen(true);
    };

    const closeModal = () => {
        closeFormModal();
    };

    return (
        <div className="w-full py-14 px-4 bg-light-blue">
            <ToastContainer />
            <div className="mx-4 md:mx-20 pb-5 text-center">
                <h2 className="tl-cyan uppercase text-3xl md:text-4xl tracking-wide">Top-reviewed agents near you</h2>
                <p className="mx-4 text-lg leading-7 tl-black-900">
                    To learn more, connect with a friendly, knowledgeable travel agent near you.
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-4 lg:mx-40">
                {agentData && (
                    <>
                        {agentData.map((agent, index) => (
                            <div
                                className="flex flex-col bg-white shadow-lg p-4 rounded text-center"
                                key={agent.agentId}
                            >
                                <div className="flex-grow">
                                    <div className="mx-auto w-36 h-36 overflow-hidden object-cover border rounded-lg">
                                        <Link to={`/agent/${agent.agentId}`} className="hover:opacity-75">
                                            <img
                                                src={agent.agentPhotoFileName}
                                                alt={`${agent.firstName} ${agent.lastName}`}
                                                className="min-w-full"
                                            />
                                        </Link>
                                    </div>
                                    <div className="w-full text-xl tl-red font-semibold mt-2 mb-1 uppercase">
                                        {agent.firstName}
                                        {' '}
                                        {agent.lastName}
                                    </div>
                                    <div className="w-full text-sm tl-black-900 font-light mb-1">
                                        {agent.city}
                                        {', '}
                                        {agent.state}
                                    </div>
                                    <div className="w-full text-sm mb-1">
                                        {agent.websiteDisplayTitle}
                                    </div>
                                    <div className="mb-1 h-8">
                                        {agent.agentRating.rating > 0
                                            && (
                                                <StarRatings
                                                    rating={agent.agentRating.rating}
                                                    starRatedColor="#0B829E"
                                                    numberOfStars={5}
                                                    name="rating"
                                                    starDimension="28px"
                                                    starSpacing="4px"
                                                />
                                            )}
                                    </div>
                                    <div className="mb-1 text-red uppercase">
                                        <Link to={`/agent/${agent.agentId}`} className="tl-red uppercase text-xs text-semibold">See full bio &gt;</Link>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                        <div className="col-span-1">
                                            <ButtonInverted
                                                title="EMAIL ME"
                                                onClick={() => emailMe(agent)}
                                            />
                                        </div>
                                    )}
                                    {agent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                        <div className="col-span-1">
                                            <ButtonInverted
                                                title="CALL ME"
                                                onClick={() => callMe(agent, () => {
                                                    setSelectedAgent(agent);
                                                    setIsPhoneFormModalOpen(true);
                                                })}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>

            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agent={selectedAgent} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agent={selectedAgent}
                            phone={selectedAgent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}
                </div>
            </Modal>
            {loading && <Loading />}
        </div>
    );
}

export default AgentList;
