/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import useAppStore from '../contexts/useAppStore';
import Button from './elements/Button';
import { customSelectStyles, filterConfig } from '../utils';
import ArrowDown from './elements/ArrowDown';

function SearchBox() {
    const {
        getBlogCategories,
        getBlogInterests,
        getBlogDestinations,
        getBlogSuppliers,

    } = useAppStore();

    const [selected, setSelected] = useState();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const label = (title) => <div className="uppercase font-bold text-sm mb-2 hidden sm:block">{title}</div>;

    const history = useHistory();

    const onSearch = () => {
        const filters = [];

        if (selected) {
            if (selected.type === 'Interests') {
                filters.push(`category=I~${encodeURIComponent(selected.value)}`);
            }
            if (selected.type === 'Destinations') {
                filters.push(`category=D~${selected.label}`);
            }
            if (selected.type === 'Categories') {
                filters.push(`category=C~${encodeURIComponent(selected.value)}`);
            }
            if (selected.type === 'Suppliers') {
                filters.push(`category=S~${encodeURIComponent(selected.value)}`);
            }
        }
        history.push(`/travelbetter_category?${filters.join('&')}`);
    };

    const groupedSpecialities = () => {
        const items = [];
        items.push({
            label: 'Categories',
            options: getBlogCategories.map((x) => ({
                value: x.categoryName,
                label: x.categoryName,
                type: 'Categories',
            })),
        });
        items.push({
            label: 'Interests',
            options: getBlogInterests.map((x) => ({
                value: x.interest1,
                label: x.interest1,
                type: 'Interests',
            })),
        });
        items.push({
            label: 'Destinations',
            options: getBlogDestinations.map((x) => ({
                value: x.destinationId,
                label: x.destination1,
                type: 'Destinations',
            })),
        });
        items.push({
            label: 'Suppliers',
            options: getBlogSuppliers.map((x) => ({
                value: x.supplierName,
                label: x.supplierName,
                type: 'Suppliers',
            })),
        });
        return items;
    };

    return (
        <div className="bg-white z-40 text-center">
            <div className="flex flex-col md:flex-row gap-4 sm:gap-8 justify-between">
                <div className="flex flex-col justify-center flex-1">
                    <div>
                        {label('Article Search')}
                        <div>

                            <Select
                                styles={customSelectStyles}
                                className="w-full rounded-full text-sm"
                                options={groupedSpecialities()}
                                components={{ DropdownIndicator: () => <ArrowDown /> }}
                                onChange={(val) => {
                                    setSelected(val);
                                    setIsMenuOpen(false);
                                }}
                                onInputChange={(val) => {
                                    if (val !== '') {
                                        setIsMenuOpen(true);
                                    } else setIsMenuOpen(false);
                                }}
                                placeholder="ex: Italy | Hawaii | Cruise | Honeymoon"
                                value={selected}
                                filterOption={createFilter(filterConfig)}
                                menuIsOpen={isMenuOpen}
                                noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                            // components={{ DropdownIndicator: () => null }}
                            />

                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center">
                    <div className="" />
                    <div className="mt-6">
                        <Button title="SEARCH" onClick={() => onSearch()} padding="py-2 px-6" />

                    </div>
                </div>
            </div>

        </div>
    );
}

export default SearchBox;
