/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
import React, {
    useEffect, useState,
} from 'react';
import parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { BLOG_AUTHOR_IMAGE_URL } from '../utils';
import urlSlug from 'url-slug';
import moment from 'moment';

function ArticlesAuthorList({
   Id,
   Name,
}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState();
    const [hasMore, setHasMore] = useState(false);

    const getAuthors = () => {
        setLoading(true);
        API.getBlogAuthors({
            authorId: Id,
            authorName: Name,
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
                if (result.data.length === currentPage) {
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };
    useEffect(() => {
        getAuthors();
    }, [currentPage]);

    let gridCSS = 'grid-cols-2 md:grid-cols-5 gap-10';
    if (Name !== '') {
        gridCSS = 'grid-cols-1 md:grid-cols-2 gap-5';
    }
    return (
        <>
            {data.length > 0
                && (
                <div className="">
                    <ToastContainer />
                    {!loading && (
                        <>
                            <div className={`grid ${gridCSS}`}>
                                {data.map((x) => (
                                    <>
                                        <div className="flex flex-col w-full" key={x.authorId}>
                                            <Link to={`/travelbetter_authors/?author=${x.author1}`}>
                                                <img
                                                    src={`${BLOG_AUTHOR_IMAGE_URL()}${x.authorImage}`}
                                                    className="h-auto w-24 md:w-48 mx-auto block rounded-full hover:scale-105 transform ease-in-out duration-1000"
                                                    alt={x.author1}
                                                />
                                                <div className="tl-red hover:text-gray-700 text-xl font-semibold mt-4 text-center">
                                                    {x.author1}
                                                </div>
                                                <div className="text-base text-gray-500 py-2 text-center">
                                                    <div className="font-bold py-1">{x.authorTitle}</div>
                                                    <div>
                                                        {x.articleCount}
                                                        {' '}
                                                        Articles written
                                                    </div>
                                                    <div className="text-sm text-gray-500 italic">
                                                        Contributor since:
                                                        {' '}
                                                        {moment(x.dateCreated).format('MMM DD,YYYY')}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        {Name !== '' ? (
                                            <div className="w-full text-xl">
                                                {x.authorBio}
                                                <div className="mt-4">
                                                    <Link to="/travelbetter_authors/" className="uppercase tl-red mx-auto py-4 hover:text-black">View all authors</Link>
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                )}
            {loading && <Loading />}
        </>
    );
}

ArticlesAuthorList.propTypes = {
    Id: PropTypes.string,
    Name: PropTypes.string,
};
ArticlesAuthorList.defaultProps = {
    Id: '',
    Name: '',
};

export default ArticlesAuthorList;
