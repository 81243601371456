/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function PhoneForm({ agent, phone, callBack }) {
    return (
        <div className="p-2 flex flex-col gap-2 max-w-2xl">
            <div className="flex flex-col justify-center items-center gap-2">
                <div className="text-l font-semibold text-center md:mx-20">
                    You can reach
                    <div className="text-red-600 text-xl font-semibold uppercase">
                        {`${agent.firstName} ${agent.lastName}`}
                    </div>
                    at&nbsp;
                    <div className="text-xl md:text-4xl font-semibold py-4 uppercase tl-cyan">
                        <a
                            href={`tel:${phone}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {`${phone}`}
                        </a>
                    </div>
                    Didn't reach me? The easiest way to connect with me is by sending an Email.
                    Send me a message and I'll contact you as soon as possible.
                </div>

                <div className="mt-10 w-full md:px-20">
                    <button
                        type="button"
                        className="bg-red-600 rounded-full py-2 px-10 text-white text-sm w-full hover:bg-red-900 text-white"
                        onClick={() => callBack()}
                    >
                        Send me a message
                    </button>
                </div>

            </div>

        </div>
    );
}

PhoneForm.propTypes = {
    agent: PropTypes.any.isRequired,
    phone: PropTypes.string.isRequired,
    callBack: PropTypes.func.isRequired,

};
export default PhoneForm;
