import React, { useEffect, useState } from 'react';
import { IoChevronBack } from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import parser from 'html-react-parser';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-slideshow-image/dist/styles.css';
import sanitizeHtml from 'sanitize-html-react';

import {
    getStateName,
} from '../utils';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import AgentLabel from '../components/AgentLabel';
import BreadCrumb from '../components/BreadCrumb';

function Itineraries() {
    const { id } = useParams();
    const [agent, setAgent] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [previewMode, setPreviewMode] = useState(query.get('preview') || false);
    const [links, setLinks] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const wrapperClass = 'md:-mx-20 -mx-2 md:px-20 pt-8 pb-4 flex flex-col';
    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,
            agentPreview: previewMode,

        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    getItineraries();
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getItineraries = () => {
        setLoading(true);
        API.agentItineraries({
            agentId: id,
            bioId: 0,
            returnAll: 'true',
            preview: 'false',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setItineraries(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
    }, []);

    useEffect(() => {
        if (agent) {
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?state=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
                url: `/agent/${id}`,
            },
            {
                label: 'All Itineraries',
            },
            ]);
        }
    }, [agent]);

    const agentBio = () => (
        <div className="flex flex-col gap-4">
            <BreadCrumb links={links} />
            <div className="text-gray-500 text-sm font-semibold flex flex-row items-center cursor-pointer gap-1" onClick={() => history.push(`/agent/${id}`)}>
                <IoChevronBack className=" text-right" />
                <div>
                    {' '}
                  Return to My Main profile
                </div>
            </div>
            <div className="flex flex-col gap-10 md:gap-20 md:flex-row justify-between items-center">

                <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-center flex-1 w-full px-10 md:px-0">
                    <div>
                        <img
                            alt=""
                            src={agent.agentPhotoFileName}
                            className="w-80 md:w-64 mx-auto rounded-lg"
                        />

                    </div>
                    <div className="flex flex-col items-center md:items-start  w-full">
                        <AgentLabel
                            agent={agent}
                        />

                    </div>
                </div>
                <div className="text-xl text-center">
                    {agent.agentRating && agent.agentRating.totalReviews > 0
                        && (
                            <div
                                className="flex flex-col gap-1 cursor-pointer"
                                onClick={() => executeScroll(reviewsRef)}
                            >
                                <div>

                                    <StarRatings
                                        rating={agent.agentRating.rating}
                                        starRatedColor="#0B829E"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="40px"
                                        starSpacing="2px"
                                    />

                                </div>
                                <div
                                    className="cursor-pointer"

                                >
                                    {agent.agentRating.totalReviews}
                                    {' '}
                                    {agent.agentRating.totalReviews > 1 ? 'reviews' : 'review'}
                                </div>
                                <div className="font-semibold">
                                    {' '}
                                    {((agent.agentRating.recommended / agent.agentRating.totalReviews) * 100).toFixed(2)}
                                    % Recommended
                                </div>
                            </div>
                        )}

                    {agent.yearsActive && (
                        <div>
                            {agent.yearsActive}
                            {' '}
                            Years of Experience
                        </div>
                    )}
                    <div className="text-red-600 text-4xl mt-4 flex flex-row gap-2 justify-center">

                        {agent.agentConnections.map((x) => (
                            <div key={x.contactMethod}>
                                {x.contactMethod === 'Facebook'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">
                                            <img
                                                alt="facebook"
                                                src="https://www.travelleaders.com/images/ico_fb_black.png"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'Instagram'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">
                                            <img
                                                alt="instagram"
                                                src="https://www.travelleaders.com/images/ico_ig_black.png"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'Twitter'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="twitter"
                                                src="https://www.travelleaders.com/images/ico_tw_black.png"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'YouTube'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="youtube"
                                                src="https://www.travelleaders.com/images/ico_yt_black.png"
                                            />
                                        </a>
                                    )}
                                {x.contactMethod === 'Pinterest'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="youtube"
                                                src="https://www.travelleaders.com/images/ico_pt_black.png"
                                            />
                                        </a>
                                    )}

                                {x.contactMethod === 'LinkedIn'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="linkedIn"
                                                src="https://www.travelleaders.com/images/ico_Li_black.png"
                                            />
                                        </a>
                                    )}

                                {x.contactMethod === 'My Blog'
                                    && (
                                        <a href={x.contactValue} target="_blank" rel="noreferrer">

                                            <img
                                                alt="youtube"
                                                src="https://www.travelleaders.com/images/ico_bl_black.png"
                                            />
                                        </a>
                                    )}
                            </div>
                        ))}

                    </div>
                </div>

            </div>
        </div>
    );

    const agentItineraries = () => (
        <div>
            {itineraries.length > 0 && (
                <div className={[wrapperClass, 'bg-white'].join(' ')}>
                    <div className="tl-cyan text-center md:text-left text-3xl px-4 uppercase">All My Itineraries</div>
                    <div className="grid grid-cols-1  md:grid-cols-3  gap-4 mt-10">
                        {itineraries.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4  cursor-pointer"
                                onClick={() => history.push(`/agent/${agent.agentId}/itinerary/${x.itineraryId}`)}
                                key={x.itineraryId}
                            >
                                <div className="hover:scale-105 transition transform  ease-in-out duration-500">
                                    <img src={x.itineraryImage} className="w-96" alt={x.title} />
                                    {' '}
                                </div>
                                <div className="text-xl font-semibold uppercase">{x.title}</div>
                                <div className="">
                                    <div className="flex float-left w-8 h-8 bg-cyan rounded-full text-center text-white justify-center items-center">
                                        <i className="fa fa-globe" aria-hidden="true" />
                                    </div>
                                    <div className="float-left pl-4">
                                        <div className="uppercase tl-cyan font-semibold -mt-1">Countries visited</div>
                                        <div className="-mt-1">
                                            {x.visitedCountries}
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="flex float-left w-8 h-8 bg-cyan rounded-full text-center text-white justify-center items-center">
                                        <i className="fa fa-calendar" aria-hidden="true" />
                                    </div>
                                    <div className="float-left pl-4">
                                        <div className="uppercase tl-cyan font-semibold -mt-1">Suggested Duration</div>
                                        <div className="-mt-1">
                                            {x.itineraryDays.length && (
                                                <>
                                                    {`${x.itineraryDays.length} Days`}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="text-base"
                                >
                                    {x.overview.length > 350 ? parser(`${sanitizeHtml(x.overview.slice(0, 350))}...<br/><span class="float-right md:float-none tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)
                                        : parser(`${sanitizeHtml(x.overview)}...<br/><span class="float-right md:float-none tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)}
                                </div>
                                <div />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
    return (
        <div className=" max-w-screen-2xl mx-auto">
            <ToastContainer />
            {agent && (
                <div className="h-full flex flex-col pt-24 md:pt-12 px-2 md:px-8 text-gray-900">
                    {agentBio()}
                    {agentItineraries()}
                </div>
            )}
            {loading && <Loading />}
        </div>
    );
}

export default Itineraries;
