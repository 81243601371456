/* eslint-disable prefer-template */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Hero from '../components/Hero';
import ArticlesList from '../components/ArticlesList';
import PromoList from '../components/PromoList';
import ButtonInverted from '../components/elements/ButtonInverted';
import Button from '../components/elements/Button';


function Inspiration() {
    const curYear = new Date().getFullYear();
    const history = useHistory();

    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }

    const trendingDestinations = [
        {
            name: 'Australia & Oceania',
            image: '/images/trndOceania.webp',
            link: '/inspiration_trending?category=oceania',
        },
        {
            name: 'Europe',
            image: '/images/trndEuro.webp',
            link: '/inspiration_trending?category=europe',
        },
        {
            name: 'Americas & The Caribbean',
            image: '/images/trndCarib.webp',
            link: '/inspiration_trending?category=americas',
        },
        {
            name: 'Asia & the Middle East',
            image: '/images/trndAsia.webp',
            link: '/inspiration_trending?category=asia',
        },
    ];
    return (
        <>
            <Helmet>
                <title>
                    {`Top ${curYear} Travel Destinations, Ideas and Supplier Partner Offers | TL Network`}
                </title>
                <meta name="title" content={`Top ${curYear} Travel Destinations, Ideas and Supplier Partner Offers | TL Network`} />
                <meta name="description" content="Get great destination and vacation ideas for planning your next vacation" />
            </Helmet>
            <Hero title="Wherever you wish to go, we’ve been there" imageFile="hero-inspiration.webp" />
            <div className=" max-w-screen-2xl mx-auto">
                <div className="min-h-screen">
                    <div className="-mt-44 relative left-0 md:mx-20 right-0 z-10">
                        <div className="bg-white md:shadow-xl rounded px-8 py-6 z-40 text-center">
                            <div className="mb-6 text-lg">
                                Get inspired to travel with our curated content of destination and vacation ideas.
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 sm:gap-8 justify-between uppercase">
                                <div className="flex flex-col justify-center flex-1">
                                    <ButtonInverted
                                        title="Vacation Ideas"
                                        onClick={() => goToAnchor('ideas')}
                                    />
                                </div>

                                <div className="flex flex-col justify-center flex-1">
                                    <ButtonInverted
                                        title="Discover New Destinations"
                                        onClick={() => goToAnchor('destinations')}
                                    />
                                </div>

                                <div className="flex flex-col justify-center flex-1">
                                    <ButtonInverted
                                        title="Get Inspired to Travel"
                                        onClick={() => goToAnchor('blogs')}
                                    />
                                </div>

                                <div className="flex flex-col justify-center flex-1">
                                    <ButtonInverted
                                        title="Trusted Travel Partners"
                                        onClick={() => goToAnchor('partners')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="ideas">
                        <PromoList
                            promoType="Featured Program"
                            promoTitle="Vacation Ideas"
                            introTxt="Discover new destinations and travel styles through the stories below. When you’re ready to learn more, contact one of our travel professionals to answer your questions and help you personalize the ideal vacation."
                            returnRows={50}
                        />
                    </div>

                    <div id="destinations" className="bg-footer-slate w-full pt-10 pb-8 px-4 text-white text-center">
                        <h2 className="text-white uppercase text-3xl">
                            Discover New Destinations
                        </h2>
                        <p className="md:mx-4 mb-4 leading-tight text-base">
                            TL Network employs travel agents who are experts on destinations spanning the globe. Discover the latest travel trends for each region below, or use the search at the top of the page to find a Travel Professional with the specific destination experience you are looking for.
                        </p>

                        <div className="grid grid-cols-2 md:grid-cols-4 grid-rows-none gap-4 mx-4 md:mx-20 py-10">
                            {trendingDestinations.map((x, index) => (
                                <div
                                    key={x.index}
                                    className="flex relative cursor-pointer hover:scale-105 transform ease-in-out duration-1000"
                                    onClick={() => history.push(x.link)}
                                >
                                    <img className="w-full z-0 rounded" src={x.image} alt={x.name} />
                                    <div className="absolute rounded-b bottom-0 bg-opacity-80 w-full text-black bg-white py-2 uppercase">
                                        {x.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="my-16 px-4 md:px-40 text-center" id="blogs">
                        <h2 className="tl-cyan uppercase text-4xl tracking-wide">
                            Get Inspired to Travel
                        </h2>
                        <p className="md:mx-4 text-lg leading-7 tl-black-900">
                            Our content creators know about all the places you want to travel. Check out our blog for more inspiration!
                        </p>
                        <h3 className="md:mx-4 mt-8 mb-4 uppercase text-xl font-semibold tracking-wide tl-black-900">
                            Destination Inspiration
                        </h3>
                        <ArticlesList
                            ArticlesCategory="Destinations"
                            ArticlesReturned="4"
                            ArticlesDisplayTitle={false}
                            ArticleDisplayTeaser={false}
                            ArticleType="C"
                            ArticleOrder="random"
                        />

                        <h3 className="mx-4 md:mx-20 mt-8 mb-4 uppercase text-xl font-semibold tracking-wide tl-black-900">
                            Travel Experiences
                        </h3>
                        <ArticlesList
                            ArticlesCategory="Experiences"
                            ArticlesReturned="4"
                            ArticlesDisplayTitle={false}
                            ArticleDisplayTeaser={false}
                            ArticleType="C"
                            ArticleOrder="random"
                        />
                        <div className="text-center mx-4 my-10">
                            <Button
                                onClick={() => history.push('/travelbetter')}
                                title="View More Travel Articles"
                                fontSize="uppercase"
                                width="max-w-sm"
                            />
                        </div>
                    </div>

                    <div className="w-full py-10 px-4 md:px-40 bg-light-blue text-center" id="partners">
                        <h2 className="tl-cyan uppercase text-4xl tracking-wide">
                            Trusted Travel Partners
                        </h2>
                        <p className="mx-4 md:mx-20 mb-4 text-lg leading-7 tl-black-900">
                            Our trusted partners help our travel agents provide you with extraordinary experiences around the globe. Discover the latest offers and information.
                        </p>
                        <div className="grid overflow-hidden grid-cols-4 md:grid-cols-8 grid-rows-none gap-2 text-center">
                            <div className="text-center">
                                <a href="inspiration_category?category=resort">
                                    <img src="/images/icon-supl-resort.png" alt="Resorts" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Resorts</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="inspiration_category?category=cruise">
                                    <img src="/images/icon-supl-cruise.png" alt="Cruises" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Cruises</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="inspiration_category?category=tour">
                                    <img src="/images/icon-supl-tour.png" alt="Tours" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Tours</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="inspiration_category?category=hotel">
                                    <img src="/images/icon-supl-hotel.png" alt="Hotels" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Hotels</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="inspiration_category?category=air">
                                    <img src="/images/icon-supl-air.png" alt="Air" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Air</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="inspiration_category?category=rail">
                                    <img src="/images/icon-supl-rail.png" alt="Rail" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Rail</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="inspiration_category?category=car">
                                    <img src="/images/icon-supl-car.png" alt="Rental Cars" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Rental Cars</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="inspiration_category?category=insurance">
                                    <img src="/images/icon-supl-insurance.png" alt="Insurance" className="m-auto w-20 md:w-24" />
                                    <p className="uppercase text-base font-bold">Insurance</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Inspiration;
