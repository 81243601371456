import React from 'react';
import PropTypes from 'prop-types';

function SocialIcon({
    iconType, iconSize, iconURL, iconColor,
}) {
    return (
        <a className={`${iconColor} cursor-pointer hover:text-gray-700`} href={iconURL} target="_blank" rel="noreferrer">
            <i className={`fab ${iconType} ${iconSize}`} />
        </a>
    );
}

SocialIcon.propTypes = {
    iconType: PropTypes.string.isRequired,
    iconSize: PropTypes.string.isRequired,
    iconURL: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
};
SocialIcon.defaultProps = {
    iconColor: 'text-white',
};

export default SocialIcon;
