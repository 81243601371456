/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import React, {
    useEffect, useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';
import PromoListCategorySupplier from '../components/PromoListCategorySupplier';
import SupplierList from '../components/SupplierList';
import ArticlesList from '../components/ArticlesList';

function InspirationCategory() {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [category, setCategory] = useState(query.get('category') || '');

    const [title, setTitle] = useState();

    const [links, setLinks] = useState([]);

    function renderSwitch(param, item) {
        let promoTitle;
        let introTxt;
        switch (param) {
            case 'cruise':
                promoTitle = 'Featured Cruise Partners';
                introTxt = 'Our agents are avid cruisers and can answer any questions you may have. Browse the cruise specials below or search for a cruise special by one of the following:';
                break;
            case 'tour':
                promoTitle = 'Featured Tour Partners';
                introTxt = 'Our agents have expert knowledege on all types of vacation packages and can help you tailor your perfect vacation of your dreams.';
                break;
            case 'hotel':
                promoTitle = 'Featured Hotel Partners';
                introTxt = 'Our agents are hotel and resort specialists and can answer any questions you may have about any property you are interested in and help you book any of these offers.';
                break;
            case 'resort':
                promoTitle = 'Featured Resort Partners';
                introTxt = 'Whether you are looking for a quick train getaway, independent rail journeys or a legendary rail experience, our expert travel agents are here for you.';
                break;
            case 'car':
                promoTitle = 'Featured Rental Car Partners';
                introTxt = 'From weekend getaways to week-long adventures, find the perfect special deal for your road or business trip.';
                break;
            case 'air':
                promoTitle = 'Featured Airline Partners';
                introTxt = 'From weekend getaways to week-long adventures, find the perfect special deal for your road or business trip.';
                break;
            case 'rail':
                promoTitle = 'Featured Rail Partners';
                introTxt = 'From weekend getaways to week-long adventures, find the perfect special deal for your road or business trip.';
                break;
            case 'insurance':
                promoTitle = 'Featured Insurance Partners';
                introTxt = 'From weekend getaways to week-long adventures, find the perfect special deal for your road or business trip.';
                break;
            default:
                promoTitle = '';
                introTxt = '';
                break;
        }
        if (item === 'title') {
            return promoTitle;
        }
        if (item === 'intro') {
            return introTxt;
        }
        return '';
    }

    useEffect(() => {
        if (category) {
            const text = `${category.charAt(0).toUpperCase() + category.slice(1)} Suppliers`;
            setTitle(text);
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Travel Inspiration',
                url: '/inspiration',
            },
            {
                label: text,
            },
            ]);
        }
    }, [category]);

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                    {' '}
                    Travel Agent Promotions  | TL Network
                </title>
                <meta name="title" content={`View all ${category} Travel Agent Promotions  | TL Network`} />
                <meta
                    name="description"
                    content={`See a listing of all  ${category} promotions currently available from TL Network.`}
                />
            </Helmet>

            <div className="min-h-screen px-4 md:px-10">
                <div className="flex flex-col gap-10 ">
                    <div className="md:ml-2  pt-16 md:pt-12">
                        <BreadCrumb links={links} />
                    </div>
                    <div className="flex flex-col gap-4 items-start max-w-screen-5xl mx-auto">
                        {category && <PromoListCategorySupplier promoType={category} promoTitle={renderSwitch(category, 'title')} introTxt={renderSwitch(category, 'intro')} />}
                    </div>
                </div>
                <div className="bg-light-blue pt-10 -mx-10 px-4 md:px-40 text-center">
                    <h2 className="uppercase text-3xl pb-10 tl-cyan">
                        All
                        {' '}
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                        {' '}
                        Partners
                    </h2>
                    <p className="tl-cyan-light md:mx-40">Representing the best in each field, our travel partners were selected for the outstanding care and value they provide you, our valued customers. Select any of the below logos to search for a travel agent who specializes in that company.</p>
                    <SupplierList SupplierType={category} />
                </div>
                <div className="bg-footer-slate pt-10 pb-8 -mx-10 px-4 md:px-40 text-white text-center">
                    <h1 className="uppercase text-3xl pb-10 text-white">
                        Get Inspired to
                            {' '}
                        {category.toLowerCase() === 'cruise' ? 'Cruise' : 'Travel'}
                    </h1>
                    <ArticlesList
                        ArticlesCategory="featured"
                        ArticlesReturned="4"
                        ArticlesDisplayTitle={false}
                        ArticleDisplayTeaser={false}
                        ArticleType="C"
                        ArticleOrder="random"
                    />
                </div>
            </div>

            {loading && <Loading />}
        </>
    );
}

export default InspirationCategory;
