/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { TLLogo } from '../utils';
import SocialIcon from './elements/SocialIcon';

function Footer() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const [hideNav, setHideNav] = useState(query.get('nav') || '');
    let displayNav = hideNav;
    const navCookie = new Cookies();
    // Check/Use Nav if in param/cookie
    if (displayNav === '0') {
        navCookie.set('hideNav', '0',
            {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }
    if (navCookie.get('hideNav')) {
        displayNav = navCookie.get('hideNav');
    }
    const leftNavigation = [
        {
            name: 'About TL Network',
            link: '/about_us',
            target: '_self',
        },
        {
            name: 'News Releases',
            link: 'https://internova.com/brands/travel-leaders-group/',
            target: '_blank',
        },
        {
            name: 'Contact Us',
            link: 'contact_us',
            target: '_self',
        },
    ];
    const rightNavigation = [
        {
            name: 'Careers',
            link: 'https://www.linkedin.com/company/internova-travel-group/jobs/',
            target: '_blank',
        },
        {
            name: 'For Agents',
            link: 'https://www.agentuniverse.com/',
            target: '_blank',
        },
        {
            name: 'Terms & Privacy Policy',
            link: 'https://internova.com/privacy/',
            target: '_blank',
        },
    ];

    if (displayNav === '0') {
        return '';
    }
    return (
        <div>

            <div className="flex items-end w-full  bg-white relative z-50">

                <footer className="w-full text-white">
                    <div className="flex flex-col flex-wrap mx-auto  items-center">
                        <div className="flex flex-col items-center bg-footer-slate w-full pt-20 pb-8 px-4">
                            <div>
                                <a className="flex items-center justify-center font-medium text-gray-400 title-font md:justify-start">
                                    <img alt="" src={TLLogo} style={{ WebkitFilter: 'brightness(0) invert(100%)', filter: 'brightness(0) invert(100%)' }} />
                                </a>
                            </div>
                            <div className="mx-auto lg:grid grid-cols-5 mt-2 gap-2 items-center justify-center text-center">
                                <div className="col-span-2 md:order-1 justify-self-center">
                                    <ul className="md:flex uppercase list-none p-0 m-0">
                                        {leftNavigation.map((x, index) => (
                                            <li className="" key={x.name}>
                                                <a href={x.link} target={x.target} className={['text-sm border-gray-500 text-white mx-auto px-2 hover:text-gray-400', index + 1 !== leftNavigation.length ? ' md:border-r-2' : ''].join(' ')}>{x.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-span-2 md:order-3 justify-self-center">
                                    <ul className="md:flex uppercase list-none p-0 m-0">
                                        {rightNavigation.map((x, index) => (
                                            <li className="" key={x.name}>
                                                <a href={x.link} target={x.target} className={['text-sm border-gray-500 text-white mx-auto px-2 hover:text-gray-400', index + 1 !== rightNavigation.length ? ' md:border-r-2' : ''].join(' ')}>{x.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-span-1 md:order-2 my-4 md:my-0 justify-self-center">
                                    <div className="flex flex-row gap-1 sm:gap-2 md:gap-2 lg:gap-4 xl:gap-8  items-center justify-center">
                                        {/*Facebook*/}
                                        <SocialIcon
                                            iconType="fa-facebook-square"
                                            iconSize="fa-lg"
                                            iconURL="https://www.facebook.com/travelleaders/"
                                        />
                                        {/*Twitter*/}
                                        <SocialIcon
                                            iconType="fa-twitter-square"
                                            iconSize="fa-lg"
                                            iconURL="https://twitter.com/travelleaders"
                                        />
                                        {/*Instagram*/}
                                        <SocialIcon
                                            iconType="fa-instagram"
                                            iconSize="fa-lg"
                                            iconURL="https://www.instagram.com/travelleaders"
                                        />
                                        {/*Linked In*/}
                                        <SocialIcon
                                            iconType="fa-linkedin"
                                            iconSize="fa-lg"
                                            iconURL="https://www.linkedin.com/company/travel-leaders-group/"
                                        />
                                        {/*YouTube*/}
                                        <SocialIcon
                                            iconType="fa-youtube"
                                            iconSize="fa-lg"
                                            iconURL="https://www.youtube.com/travelleaders"
                                        />
                                        {/*Pinterest*/}
                                        <SocialIcon
                                            iconType="fa-pinterest-square"
                                            iconSize="fa-lg"
                                            iconURL="https://www.pinterest.com/TravelLeaders/"
                                        />
                                        {/*TikTok*/}
                                        <SocialIcon
                                            iconType="fa-tiktok"
                                            iconSize="fa-lg"
                                            iconURL="https://www.tiktok.com/@travelleadersnetwork"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-900 w-full px-2 py-10 full-length">
                            <div className="md:grid grid-cols-3 gap-4 justify-items-center text-center md:text-left px-4">
                                <div className="col-span-1">
                                    <h3 className="font-semibold uppercase tracking-wide">Agents by Interest</h3>
                                    <div className="md:grid grid-cols-1 gap-1">
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=All+Inclusive" className="hover:text-red-600">All-Inclusive specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Destination+Weddings" className="hover:text-red-600">Destination wedding specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Family+Vacations" className="hover:text-red-600">Family vacation specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Honeymoons" className="hover:text-red-600">Honeymoon specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Beach+vacations" className="hover:text-red-600">Beach vacation specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Couples+%26+Romance" className="hover:text-red-600">Couple &amp; romance specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=River+Cruises" className="hover:text-red-600">River cruise specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Escorted+Tours" className="hover:text-red-600">Escorted tour specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Golf" className="hover:text-red-600">Golf travel specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctInterest=Adult+Only" className="hover:text-red-600">Adults-only specialists</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/find_travel_agent#interest" className="hover:text-red-600">View more &gt;</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h3 className="font-semibold uppercase tracking-wide">Agents by Destination</h3>
                                    <div className="md:grid grid-cols-2 gap-1">
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Mexico&destinationId=106337" className=" hover:text-red-600">Mexico experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Caribbean&destinationId=62224" className="hover:text-red-600">Caribbean experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Europe&destinationId=62227" className="hover:text-red-600">Europe experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Australia&destinationId=62294" className="hover:text-red-600">Australia experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Cuba&destinationId=62323" className="hover:text-red-600">Cuba experts</a></p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Japan&destinationId=62355" className="hover:text-red-600">Japan experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Fiji&destinationId=62300" className="hover:text-red-600">Fiji experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=New+Zealand&destinationId=62318" className="hover:text-red-600">New Zealand experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Aruba&destinationId=62340" className="hover:text-red-600">Aruba experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/agents?slctDestination=Jamaica&destinationId=62320" className="hover:text-red-600">Jamaica experts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/find_travel_agent#destination" className="hover:text-red-600">View more &gt;</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h3 className="font-semibold uppercase tracking-wide">Travel Specials</h3>
                                    <div className="md:grid grid-cols-1 gap-1">
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/promotion_category?category=cruise" className="hover:text-red-600">Cruises</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/promotion_category?category=hotel" className="hover:text-red-600">Hotels &amp; resorts</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/promotion_category?category=tour" className="hover:text-red-600">Vacation packages</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/promotion_category?category=car" className="hover:text-red-600">Car packages</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/promotion_category?category=rail" className="hover:text-red-600">Rail packages</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/inspiration" className="hover:text-red-600">Travel inspiration</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="/promotions" className="hover:text-red-600">View all specials &gt;</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </footer>

            </div>
        </div>
    );
}

export default Footer;
