/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Home from './pages/Home';
import FindATravelAgent from './pages/FindATravelAgent';
import Agents from './pages/Agents';
import Agent from './pages/Agent';
import Bio from './pages/Bio';
import Itineraries from './pages/Itineraries';
import Itinerary from './pages/Itinerary';
import Story from './pages/Story';
import Promotions from './pages/Promotions';
import Inspiration from './pages/Inspiration';
import PromotionCategory from './pages/PromotionCategory';
import InspirationCategory from './pages/InspirationCategory';
import InspirationTrending from './pages/InspirationTrending';
import Blogs from './pages/Blogs';
import BlogCategory from './pages/BlogCategory';
import Blog from './pages/Blog';
import BlogAuthors from './pages/BlogAuthors';
import Promotion from './pages/Promotion';
import AgencyLocator from './pages/AgencyLocator';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import ThankYou from './pages/ThankYou';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import { AppContextProvider } from './contexts/AppContext';
import Footer from './components/Footer';

function App() {
    let marginTop = 'md:pt-16';

    useEffect(() => {
        if (window.location.search.includes('utm')) {
            const params = window.location.search.slice(1).split('&').reduce((acc, s) => {
                const [k, v] = s.split('=');
                if (!k.includes('utm') && !k.includes('gclid')) {
                    return {};
                }
                return Object.assign(acc, { [k]: v });
            }, {});
            const cookies = new Cookies();
            cookies.set('google-utm', JSON.stringify(params),
                { expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)), path: '/' });
        }
    }, []);

    const queryParams = new URLSearchParams(window.location.search);
    const hasNav = queryParams.get('nav');
    const navCookie = new Cookies();

    if ((hasNav === '0') || (navCookie.get('hideNav'))) {
        marginTop = '';
    }

    return (
        <AppContextProvider>
            <Router forceRefresh>

                <div className="bg-white mx-auto">
                    <Header />
                    <div className={`w-full ${marginTop}`}>
                        <Switch>
                            <Route path="/" component={Home} exact />
                            <Route path="/find_travel_agent" component={FindATravelAgent} exact />
                            <Route path="/agents" component={Agents} exact />
                            <Route path="/agent/:id" component={Agent} exact />
                            <Route path="/agent/:id/bio/:bioId" component={Bio} exact />
                            <Route path="/agent/:id/story/:storyId" component={Story} exact />
                            <Route path="/agent/:id/bio/:bioId/story/:storyId" component={Story} exact />
                            <Route path="/agent/:id/itineraries/" component={Itineraries} exact />
                            <Route path="/agent/:id/itinerary/:itineraryId" component={Itinerary} exact />
                            <Route path="/agent/:id/bio/:bioId/itinerary/:itineraryId" component={Itinerary} exact />
                            <Route path="/promotions" component={Promotions} exact />
                            <Route path="/promotion_category" component={PromotionCategory} exact />
                            <Route path="/promotions/:id" component={Promotion} exact />
                            <Route path="/inspiration" component={Inspiration} exact />
                            <Route path="/inspiration_category" component={InspirationCategory} exact />
                            <Route path="/inspiration_trending" component={InspirationTrending} exact />
                            <Route path="/travelbetter" component={Blogs} exact />
                            <Route path="/travelbetter_category" component={BlogCategory} exact />
                            <Route path="/travelbetter/:id/:title" component={Blog} exact />
                            <Route path="/travelbetter_authors/" component={BlogAuthors} exact />
                            <Route path="/agency_locator/" component={AgencyLocator} exact />
                            <Route path="/about_us/" component={AboutUs} exact />
                            <Route path="/contact_us/" component={ContactUs} exact />
                            <Route path="/thankYou" component={ThankYou} exact />
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </div>
                    <Footer />

                </div>
            </Router>
        </AppContextProvider>
    );
}

export default App;
