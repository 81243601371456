/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const slideImages = [
    {
        url: '/images/supl-globus-white.png',
        caption: 'Globus logo',
    },
    {
        url: '/images/supl-palace-white.png',
        caption: 'Palace Resorts logo',
    },
    {
        url: '/images/supl-celebrity-white.png',
        caption: 'Celebrity Cruises logo',
    },
    {
        url: '/images/supl-RCI-white.png',
        caption: 'Royal Caribbean Cruises logo',
    },
    {
        url: '/images/supl-sandals-white.png',
        caption: 'Sandals logo',
    },
    {
        url: '/images/supl-viking-white.png',
        caption: 'Sandals logo',
    },
    {
        url: '/images/supl-RSSC-white.png',
        caption: 'Sandals logo',
    },
    {
        url: '/images/supl-princess-white.png',
        caption: 'Sandals logo',
    },
    {
        url: '/images/supl-NCL-white.png',
        caption: 'Sandals logo',
    },
    {
        url: '/images/supl-funjet-white.png',
        caption: 'Sandals logo',
    },
    {
        url: '/images/supl-amawaterways-white.png',
        caption: 'Sandals logo',
    },
];


const HomePartners = () => {
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '150px',
    };

    const properties = {
        duration: 3000,
        autoplay: true,
        indicators: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: false,
                    infinte: false,
                },
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
        transitionDuration: 500,
    };
    return (
        <div className="bg-footer-slate w-full py-12 px-4 text-center">
            <h3 className="uppercase text-xl mb-2 font-semibold tracking-wide text-white">TL Network top supplier partners</h3>
            <Slide {...properties}>
                {slideImages.map((slideImage, index) => (
                    <div className="each-slide" key={index} style={style}>
                        <img src={`${slideImage.url}`} alt={`${slideImage.caption}`} />
                    </div>
                ))}
            </Slide>
        </div>
    );
};

export default HomePartners;
