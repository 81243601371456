/* eslint-disable prefer-template*/
/* eslint-disable prefer-spread */
/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useLocation, useHistory } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import Modal from 'react-modal';
import Select, { createFilter } from 'react-select';
import Cookies from 'universal-cookie';
import parser from 'html-react-parser';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import useMobileDetect from 'use-mobile-detect-hook';
import Tooltip from 'react-simple-tooltip';
import Button from '../components/elements/Button';
import ButtonInverted from '../components/elements/ButtonInverted';
import AgentAffiliation from '../components/elements/AgentAffiliation';
import 'react-loading-skeleton/dist/skeleton.css';
import Loading from '../components/elements/Loading';
import LoadingInline from '../components/elements/LoadingInline';
import SelectInput from '../components/elements/SelectInput';
import useAppStore from '../contexts/useAppStore';
import API from '../services/API';
import {
    ADVERT_IMAGE_URL,
    callMe,
    CONTACT_METHOD_EMAIL,
    CONTACT_METHOD_PHONE,
    CONTACT_METHOD_VIDEO,
    customSelectStyles, filterConfig, getStateName, modalCustomStyles,
} from '../utils';
import EmailForm from '../components/EmailForm';
import PhoneForm from '../components/PhoneForm';
import 'react-toastify/dist/ReactToastify.css';
import Destination from '../components/search/Destination';
import Interest from '../components/search/Interest';
import Supplier from '../components/search/Supplier';
import Language from '../components/search/Language';
import State from '../components/search/State';
import Input from '../components/elements/Input';
import BreadCrumb from '../components/BreadCrumb';
import AppointmentForm from '../components/AppointmentForm';
import EmergencyEmailForm from '../components/EmergencyEmailForm';
/*  Related Searches Not used for Canadian Site import RelatedDestByInt from '../components/search/RelatedDestByInt';
import RelatedDestByState from '../components/search/RelatedDestByState';
import RelatedIntByState from '../components/search/RelatedIntByState';
import RelatedIntByDest from '../components/search/RelatedIntByDest';
import RelatedMetrosByState from '../components/search/RelatedMetrosByState';
import RelatedMetrosByInt from '../components/search/RelatedMetrosByInt';
import RelatedDestIntByGeo from '../components/search/RelatedDestIntByGeo'; */

function Agents() {
    const history = useHistory();
    const detectMobile = useMobileDetect();
    const location = useLocation();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const userLocationCookie = new Cookies();

    const {
        getStates,
        getDestinations,
        getSpecialities,
        setFetchingAgents,
        isFetchingAgent,
    } = useAppStore();

    const [currentPage, setCurrentPage] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState({
        destination: query.get('destinationId') || '',
        state: query.get('slctState') || '',
        interest: query.get('slctInterest') || '',
        metro: query.get('slctMetro') || '',
        agentName: query.get('txtAgentName') || '',
        zipCode: query.get('zipCode') || '',
        language: query.get('slctLanguage') || '',
        supplier: query.get('slctSupplier') || '',
        city: query.get('slctCity') || '',
        slctDestination: query.get('slctDestination') || '',
    });

    function filterCount() {
        let x = 0;
        if (activeFilters.slctDestination.length > 0) x += 1;
        if ((activeFilters.state.length > 0) || (activeFilters.metro.length > 0)) x += 1;
        if (activeFilters.interest.length > 0) x += 1;
        if (activeFilters.agentName.length > 0) x += 1;
        if (activeFilters.zipCode.length > 0) x += 1;
        if (activeFilters.supplier.length > 0) x += 1;
        if (activeFilters.city.length > 0) x += 1;
        return x;
    }

    const [links, setLinks] = useState([{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a travel Agent',
        url: '/find_travel_agent',
    },
    {
        label: 'All Agents',

    },
    ]);
    // Search Filters
    const [selectedDestination, setSelectedDestination] = useState(query.get('destinationId') || '');

    const [selectedDestinationObject, setSelectedDestinationObject] = useState(query.get('slctDestination') ? {
        value: query.get('destinationId'),
        label: query.get('slctDestination'),
    } : null);
    const [selectedState, setSelectedState] = useState(query.get('slctState') || '');
    const [selectedInterest, setSelectedInterest] = useState(query.get('slctInterest') || '');

    /**
   * Temp
   */
    const [selectedDestinationObjectTemp, setSelectedDestinationObjectTemp] = useState(query.get('destinationId') ? {
        value: query.get('destinationId') ? parseInt(query.get('destinationId')) : 0,
        label: query.get('slctDestination') || '',
    } : null);
    const [selectedZipCodeTemp, setSelectedZipCodeTemp] = useState(query.get('zipCode') || '');
    const [selectedInterestTemp, setSelectedInterestTemp] = useState(query.get('slctInterest') || '');

    const [selectedMetro, setSelectedMetro] = useState(query.get('slctMetro') || '');
    const [selectedCity, setSelectedCity] = useState(query.get('slctCity') || '');
    const [agentName, setAgentName] = useState(query.get('txtAgentName') || '');
    const [zipCode, setZipCode] = useState(query.get('zipCode') || '');

    const [selectedLanguage, setSelectedLanguage] = useState(query.get('slctLanguage') || '');
    const [selectedSupplier, setSelectedSupplier] = useState(query.get('slctSupplier') || '');
    const [selectedAgentId, setSelectedAgentId] = useState(query.get('agentId') || '');
    // Agents
    const [agents, setAgents] = useState();
    //Advertisements
    const [ads, setAds] = useState([]);
    const [isFetchingAgentMore, setFetchingAgentsMore] = useState();
    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, setIsAppointmentFormModalOpen,
        isEmailFormModalOpen, isPhoneFormModalOpen, isAppointmentFormModalOpen,
        isEmergencyFromModalOpen, closeFormModal,
    } = useAppStore();
    const [isReady, setIsReady] = useState();
    const [selectedAgent, setSelectedAgent] = useState();
    const [dynamicTitle, setDynamicTitle] = useState();

    const [filter, setFilter] = useState(false);

    const amendDestination = (data, dest) => {
        if (dest === '') {
            return data;
        }
        let localeName = null;
        let areaName = null;
        let regionName = null;
        let destinationArr = [];
        const amendedDestinations = data || [];

        if (getDestinations.some((x) => x.destinationId === parseInt(dest))) {
            const d = getDestinations.find((z) => z.destinationId === parseInt(dest));
            destinationArr = d.destination?.split('|');

            if (destinationArr.length === 3) {
                localeName = destinationArr[0].trim();
                areaName = destinationArr[1].trim();
                regionName = destinationArr[2].trim();
            } else if (destinationArr.length === 2) {
                areaName = destinationArr[0].trim();
                regionName = destinationArr[1].trim();
            } else if (destinationArr.length === 1) {
                regionName = destinationArr[0].trim();
            }

            if (data && !data.some((x) => x.localeName === localeName
                && x.regionName === regionName
                && x.areaName === areaName)) {
                amendedDestinations.push({
                    locationType: destinationArr.length === 3 ? 'Cit' : (destinationArr.length === 2 ? 'Are' : 'Reg'),
                    regionName,
                    areaName,
                    localeName,
                });
            }
        } else {
            amendedDestinations.push({
                locationType: destinationArr.length === 3 ? 'Cit' : (destinationArr.length === 2 ? 'Are' : 'Reg'),
                regionName,
                areaName,
                localeName,
            });
        }
        return amendedDestinations;
    };

    const amendInterest = (data, inrst) => {
        if (inrst === '') {
            return data;
        }
        const amendedInterest = data || [];
        if (data.length > 0) {
            if (!data.some((x) => x.interestName === inrst)) {
                amendedInterest.push({
                    interestName: inrst,
                });
            }
        } else {
            amendedInterest.push({
                interestName: inrst,
            });
        }
        return amendedInterest;
    };
    const onSearch = (clearPrevious = true) => {
        if (!clearPrevious) { setFetchingAgentsMore(true); } else { setFetchingAgents(true); }
        if (clearPrevious) {
            setCurrentPage(0);
        }

        API.fetchAgents({
            zip: zipCode || activeFilters.zipCode,
            name: agentName || activeFilters.agentName,
            state: selectedState || activeFilters.state,
            destination: selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : getDestinationName(),
            interest: selectedInterest || activeFilters.interest,
            metroRegion: selectedMetro || activeFilters.metro,
            language: selectedLanguage || activeFilters.language,
            supplier: selectedSupplier || activeFilters.supplier,
            city: selectedCity || activeFilters.city,
            agentId: selectedAgentId || 0,
            currentPage: clearPrevious ? 0 : currentPage,
        }).then((result) => {
            if (result.responseStatus === 1) {
                if (!agents || clearPrevious) {
                    result.data.agent.map((y) => {
                        y.destination = amendDestination(y.destination ? y.destination.splice(0, 5) : [],
                            selectedDestinationObject ? selectedDestinationObject.value
                                : activeFilters.destination);

                        y.interest = amendInterest(y.interest ? y.interest.splice(0, 5) : [],
                            selectedInterest || activeFilters.interest);
                    });
                    setAgents(result.data);
                } else {
                    result.data.agent.map((y) => {
                        y.destination = amendDestination(y.destination ? y.destination.splice(0, 5) : [],
                            selectedDestinationObject ? selectedDestinationObject.value
                                : activeFilters.destination);
                        y.interest = amendInterest(y.interest ? y.interest.splice(0, 5) : [],
                            selectedInterest || activeFilters.interest);
                    });

                    agents.agent = agents.agent.concat(result.data.agent);

                    agents.totalAgents = result.data.totalAgents;
                    setAgents({ ...agents });
                }

                setActiveFilters(

                    {
                        destination: selectedDestinationObject ? selectedDestinationObject.value
                            : activeFilters.destination,
                        state: selectedState || activeFilters.state,
                        interest: selectedInterest || activeFilters.interest,
                        metro: selectedMetro || activeFilters.metro,
                        agentName: agentName || activeFilters.agentName,
                        zipCode: zipCode || activeFilters.zipCode,
                        language: selectedLanguage || activeFilters.language,
                        supplier: selectedSupplier || activeFilters.supplier,
                        city: selectedCity || activeFilters.city,
                        slctDestination: selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim()
                            : activeFilters.slctDestination,
                    },

                );
            } else {
                toast.error(result.error.message);
            }

            setFetchingAgents(false);
            setFetchingAgentsMore(false);
            setIsReady(false);
        });
    };

    const onRefineSearch = () => {
        setSelectedInterest(selectedInterestTemp);
        setSelectedDestinationObject(selectedDestinationObjectTemp);
        setSelectedState();
        setActiveFilters(
            {
                ...activeFilters, state: '',
            },
        );
        setZipCode(selectedZipCodeTemp);
        // onSearch();
    };

    const emailMe = (agent) => {
        // connect.find((x) => x.contactMethod === 'by Email')?.contactValue
        setSelectedAgent(agent);
        setIsEmailFormModalOpen(true);
    };

    const constructTitle = () => {
        let title = '';

        if (activeFilters.language) {
            title += `${activeFilters.language} speaking `;
        }
        if (activeFilters.slctDestination || activeFilters.interest) {
            if (activeFilters.slctDestination) { title += `${activeFilters.slctDestination} `; }
            if (activeFilters.interest) { title += `${activeFilters.interest} `; }
        }

        if (activeFilters.state) {
            title += `Travel Agents in ${activeFilters.metro && !activeFilters.city ? `${activeFilters.metro},` : ''} ${activeFilters.city ? `${activeFilters.city}` : ''} 
    ${getStateName(activeFilters.state)}`;
        } else if (activeFilters.zipCode) { title += `Travel Agents near ${activeFilters.zipCode}`; } else { title += 'Travel Agents'; }

        if (activeFilters.supplier) {
            title += ` working with ${activeFilters.supplier} `;
        }

        if (activeFilters.agentName) {
            title += ` named ${activeFilters.agentName} `;
        }

        setDynamicTitle(title);
    };

    const goToAgentBio = (agent, section = '') => {
        let bio;
        let searchTerms = '';
        if (window.location.search.substr(1).length > 0) {
            searchTerms = '&' + window.location.search.substr(1);
        }
        if (agent.bioId === null) {
           bio = '';
        } else {
           bio = '/bio/' + agent.bioId;
        }
        if (section !== '') {
            history.push(`/agent/${agent.agentId + bio}?s=${section}&ref=search${searchTerms}`);
        } else {
            history.push(`/agent/${agent.agentId + bio}?ref=search${searchTerms}`);
        }
    };
    const closeModal = () => {
        closeFormModal();
    };

    const getAds = () => {
        API.getAdverts({
            destination: selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : getDestinationName(),
            interest: selectedInterest || activeFilters.interest,
            supplier: selectedSupplier || activeFilters.supplier,
        }).then((adResult) => {
            if (adResult.responseStatus === 1) {
                setAds(adResult.data.sort((a, b) => b.sortOrder - a.sortOrder).map((x) => x));
            } else { toast(adResult.error.message); }
        });
    };

    const getDestinationName = () => {
        if (getDestinations
            .some((x) => x.destinationId === parseInt(activeFilters.destination))) {
            return getDestinations
                .find((x) => x.destinationId === parseInt(activeFilters.destination)).destination?.split('|')[0].trim();
        }
        return activeFilters.slctDestination;
    };

    //get user location details and set cookie
    const myIp = query.get('ip') || null;
    let userCountry = '';
    let userState = '';
    let userMetro = '';

    const getUserLocation = async () => {
        isFetchingAgent(true);
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*Populate user location*/
                userCountry = ipResult.data.country_code;
                userState = ipResult.data.region_name;
                userMetro = ipResult.data.metro_region;
            } else {
                toast(ipResult.error.message);
            }
            isFetchingAgent(false);
        });
    };
    /*if cookie is not set, run userlocation*/
    useEffect(() => {
        if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
            getUserLocation();
        }
    }, [userLocationCookie]);

    /*otherwise use cookie user data*/
    if ((userLocationCookie.get('userLocation')) && myIp === null) {
        userCountry = userLocationCookie.get('userLocation').country_code;
        userState = userLocationCookie.get('userLocation').region_name;
        userMetro = userLocationCookie.get('userLocation').metro_region;
    }

    useEffect(() => {
        if (isReady) {
            onSearch();
            window.scrollTo(0, 0);
        }
    }, [isReady]);

    useEffect(() => {
        constructTitle();
        if ((activeFilters.destination !== '' || activeFilters.interest !== '' || activeFilters.supplier !== '') && isReady) {
            getAds();
        } else {
            setAds([]);
        }
    }, [activeFilters, isReady]);

    useEffect(() => {
        if (getDestinations.length > 0) {
            setIsReady(true);
        }
    }, [getDestinations]);

    useEffect(() => {
        if (currentPage > 0) { onSearch(false); }
    }, [currentPage]);

    useEffect(() => history.listen((a) => {
        if (history.action === 'PUSH') {
            window.location.reload();
        }

        if (history.action === 'POP') {
            window.location.reload();
        }
    }), []);

    /*WINDOW RESIZE CHECK*/
    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    /*END WINDOW RESIZE CHECK*/

    /*STICKY FILTER */
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);
    /* Method that will fix search and hide nav after a specific scrollable */
    const isSticky = (e) => {
        const stickyFilter = document.querySelector('.sticky-filter');
        const header = document.querySelector('.header');
        const scrollTop = window.scrollY;
        if (stickyFilter) {
            scrollTop >= 275 ? stickyFilter.classList.add('is-sticky') : stickyFilter.classList.remove('is-sticky');
            scrollTop >= 300 ? header.classList.add('hide-nav') : header.classList.remove('hide-nav');
        }
    };
    /*END STICKY */

    const handleWindowSizeChange = () => {
        if (windowSize.innerWidth < 768) { setFilter(false); } else { setFilter(true); }
    };
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const searchViaRoute = () => {
        const filters = [];
        if (zipCode) {
            filters.push(`zipCode=${encodeURIComponent(zipCode)}`);
        }
        if (selectedInterest) {
            filters.push(`slctInterest=${encodeURIComponent(selectedInterest)}`);
        }
        if (selectedDestinationObject) {
            filters.push(`slctDestination=${encodeURIComponent(selectedDestinationObject?.label?.split('|')[0].trim())}&destinationId=${selectedDestinationObject.value}`);
        }
        if (selectedState) {
            filters.push(`slctState=${encodeURIComponent(selectedState)}`);
            if (selectedMetro) {
                filters.push(`slctMetro=${encodeURIComponent(selectedMetro)}`);
            }
        }
        if (selectedCity) {
            filters.push(`slctCity=${encodeURIComponent(selectedCity)}`);
        }
        if (selectedLanguage) {
            filters.push(`slctLanguage=${encodeURIComponent(selectedLanguage)}`);
        }

        if (selectedSupplier) {
            filters.push(`slctSupplier=${encodeURIComponent(selectedSupplier)}`);
        }

        history.push(`/agents?${filters.join('&')}`);

        // history.go();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [filter]);

    const constructLinks = () => {
        const base = '/agents';
        const baseLinks = [{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Find a Travel Agent',
            url: '/find_travel_agent',
        },

        ];

        if (query.get('slctState')) {
            baseLinks.push({
                label: getStateName(query.get('slctState')),
                url: `${base}?slctState=${query.get('slctState')}`,

            });
            if (query.get('slctMetro')) {
                if (query.get('slctCity')) {
                    baseLinks.push({
                        label: query.get('slctCity'),
                        url: `${base}?slctState=${query.get('slctState')}&slctMetro=${query.get('slctMetro')}&slctCity=${query.get('slctCity')}`,

                    });
                } else {
                    baseLinks.push({
                        label: query.get('slctMetro'),
                        url: `${base}?slctState=${query.get('slctState')}&slctMetro=${query.get('slctMetro')}`,

                    });
                }
            }
        }
        if (query.get('destinationId')) {
            let url = `${base}?slctDestination=${query.get('slctDestination')}&destinationId=${query.get('destinationId')}`;

            if (query.get('slctState')) {
                url += `&slctState=${query.get('slctState')}`;
            }

            if (query.get('slctMetro')) {
                url += `&slctMetro=${query.get('slctMetro')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('slctDestination').split('|')[0].trim()),
                url,
            });
        }
        if (query.get('slctInterest')) {
            // interest destination state/metro

            let url = `${base}?slctInterest=${query.get('slctInterest')}`;

            if (query.get('slctState')) {
                url += `&slctState=${query.get('slctState')}`;
            }

            if (query.get('slctMetro')) {
                url += `&slctMetro=${query.get('slctMetro')}`;
            }

            if (query.get('destinationId')) {
                url += `&slctDestination=${query.get('slctDestination')}&destinationId=${query.get('destinationId')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('slctInterest')),
                url,
            });
        }

        if (query.get('slctSupplier')) {
            // interest destination state/metro interest

            let url = `${base}?slctSupplier=${query.get('slctSupplier')}`;

            if (query.get('slctState')) {
                url += `&slctState=${query.get('slctState')}`;
            }

            if (query.get('slctMetro')) {
                url += `&slctMetro=${query.get('slctMetro')}`;
            }

            if (query.get('destinationId')) {
                url += `&slctDestination=${query.get('slctDestination')}&destinationId=${query.get('destinationId')}`;
            }
            if (query.get('slctInterest')) {
                url += `&slctInterest=${query.get('slctInterest')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('slctSupplier')),
                url,
            });
        }
        if (query.get('slctLanguage')) {
            // interest destination state/metro supplier

            let url = `${base}?slctLanguage=${query.get('slctLanguage')}`;

            if (query.get('slctState')) {
                url += `&slctState=${query.get('slctState')}`;
            }

            if (query.get('slctMetro')) {
                url += `&slctMetro=${query.get('slctMetro')}`;
            }

            if (query.get('destinationId')) {
                url += `&slctDestination=${query.get('slctDestination')}&destinationId=${query.get('destinationId')}`;
            }
            if (query.get('slctSupplier')) {
                url += `&slctSupplier=${query.get('slctSupplier')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('slctLanguage')),
                url,
            });
        }

        if (query.get('txtAgentName') || query.get('zipCode')) {
            baseLinks.push({
                label: 'All Agents',

            });
        }

        if (location.search.indexOf('=') === -1) {
            baseLinks.push({
                label: 'All Agents',

            });
        }
        setLinks(baseLinks);
    };

    useEffect(() => {
        constructLinks();
    }, []);

    const getAgentBio = (agent, callback) => {
        isFetchingAgent(true);
        API.agentBio({
            agentId: agent.agentId,
            agentPreview: false,
        }).then((result) => {
            if (result.responseStatus === 1) {
                setSelectedAgent(result.data);
                if (callback) { callback(); }
            } else { toast(result.error.message); }
            isFetchingAgent(false);
        });
    };

    const onVideoAppointment = (agent) => {
        getAgentBio(agent, () => setIsAppointmentFormModalOpen(true));
    };

    const label = (val) => <div className="my-2 font-semibold text-sm uppercase text-black">{val}</div>;

    const agentCard = (agent) => (
        <div className="flex flex-col md:flex-row gap-2 p-2 md:p-4 bg-white rounded-lg shadow-lg justify-between mx-2" key={agent.agentId}>
            <div>
                <div className="flex flex-col md:flex-col gap-1 text-center text-xs md:w-40">
                    <div className="flex w-full h-72 md:h-48 rounded-lg overflow-hidden">
                        <img
                            alt={`${agent.firstName} ${agent.lastName}`}
                            src={agent.agentPhotoFileName}
                            className="mx-auto  cursor-pointer agent-image rounded-lg"
                            loading="lazy"
                            onClick={() => goToAgentBio(agent)}
                        />
                    </div>
                    <div className="flex flex-col mt-2 ">

                        {agent.agentRating && agent.agentRating.length > 0
                            && (
                                <div className="cursor-pointer" onClick={() => goToAgentBio(agent, 'review')}>
                                    <div>

                                        <StarRatings
                                            rating={agent.agentRating[0].rating}
                                            starRatedColor="#0B829E"
                                            numberOfStars={5}
                                            name="rating"
                                            starDimension="20px"
                                            starSpacing="2px"
                                        />

                                    </div>
                                    <div>
                                        {agent.agentRating[0].totalReviews}
                                        {' '}
                                        reviews
                                    </div>
                                    <div>
                                        {' '}
                                        {((agent.agentRating[0].recommended / agent.agentRating[0].totalReviews) * 100).toFixed(0)}
                                        % Recommended
                                    </div>
                                </div>
                            )}

                        {agent.yearsActive && (
                            <div>
                                {agent.yearsActive}
                                {' '}
                                Years of Experience
                            </div>
                        )}
                    </div>
                </div>

            </div>
            <div className="w-full px-2">
                <div className="flex flex-col h-full">
                    {agent.preferredAgent === 1 ? (
                        <div className="text-base">
                            <Tooltip placement="bottom" padding={5} content="A Super Agent is a top agent based on their detailed profile, level of expertise, and positive customer feedback.">
                                <img src="/images/icon-super-agent.svg" height="23" width="100" alt="Super Agent" loading="lazy" />
                            </Tooltip>
                        </div>
                    ) : ('')}
                    <div className="text-red-600 text-xl font-semibold cursor-pointer uppercase" onClick={() => goToAgentBio(agent)}>{`${agent.firstName} ${agent.lastName}`}</div>
                    <div className="text-gray-500 text-sm">{`${agent.city}, ${agent.state} `}</div>

                    <div className="text-gray-500 text-sm">
                        <AgentAffiliation
                            icAgent={agent.isOutsideSales}
                            agencyLevel={agent.agencyLevel}
                            agencyRecordType={agent.agencyRecordType}
                            icBusinnessName={agent.icBusinessName}
                            agencyName={agent.agencyName}
                            hostAgency={agent.hostAgency}
                            securityLevel={agent.securityLevel}
                        />
                    </div>
                    {agent.websiteDisplayTitle && (
                        <div className="tl-cyan text-base my-2 uppercase">
                            {agent.websiteDisplayTitle}
                        </div>
                    )}
                    <div className="flex flex-row gap-4 text-sm mt-2">
                        {agent.destination && (
                            <div>
                                <span className="mr-1 inline-block font-semibold">Destination Specialties:</span>
                                {agent.destination && agent.destination.map((x, index) => (
                                    <span className="mr-1 inline-block" key={`${index}-dest`}>
                                        {selectedDestinationObject?.label?.split('|')[0].trim() === x.localeName || selectedDestinationObject?.label?.split('|')[0].trim() === x.areaName || selectedDestinationObject?.label?.split('|')[0].trim() === x.regionName ? (
                                            <span className="font-semibold italic">{x.locationType === 'Cit' ? x.localeName : (x.locationType === 'Reg' ? x.regionName : x.areaName)}</span>
                                        ) : (
                                                <>
                                                    {x.locationType === 'Cit' ? x.localeName : (x.locationType === 'Reg' ? x.regionName : x.areaName)}
                                                </>
                                            )}
                                        {index + 1 < agent.destination.length ? ',' : ''}
                                    </span>
                                ))}

                            </div>
                        )}
                        {agent.interest && (
                            <div>
                                <span className="mr-1 inline-block font-semibold">Interest Specialties:</span>
                                {agent.interest && agent.interest.map((x, index) => (
                                    <span className="mr-1 inline-block" key={`${index}-int`}>
                                        {selectedInterest === x.interestName ? (
                                            <span className="font-semibold italic">{x.interestName}</span>
                                        ) : x.interestName }
                                        {index + 1 < agent.interest.length ? ',' : ''}
                                    </span>
                                ))}

                            </div>
                        )}
                    </div>
                    {agent.isOffline === '1' && (
                        <div className="flex justify-center items-center w-full mt-8 text-lg text-grey-900 font-semibold">
                            {agent.isOfflineMsg ? agent.isOfflineMsg : 'I am currently not accepting customers inquiries'}
                        </div>
                    )}

                    {agent.topReview && (
                        <div className="flex flex-col gap-1 text-xs  self-end mt-auto w-full pt-2">
                            <div className="font-bold">{`${agent.topReview[0].topReviewName.replace(/&amp;/g, '&')} says`}</div>
                            <div className="italic text-neutral-600 border-neutral-500 quote cursor-pointer" onClick={() => goToAgentBio(agent, 'review')}>
                                {`"${parser(agent.topReview[0].topReview)}"`}
                                ...Read More
                            </div>
                        </div>
                    )}
                </div>

            </div>
            <div className="">
                {((agent.agencyLevel === 'Enterprise' || agent.agencyLevel === 'Executive' || agent.agencyLevel === 'Professional' || agent.agencyLevel === 'Legacy') && (agent.isOutsideSales === '0')) && (
                    <div className="m-auto text-base">
                        <Tooltip placement="bottom" padding={5} content="This agent provides outstanding service and expert advice from a full-service TL Network branded agency">
                            <img src="/images/logo_TL_itb1.png?ver=1" className="m-auto w-48" alt="TL Network Logo" loading="lazy" title="This agent provides outstanding service and expert advice from a full-service TL Network branded agency" />
                        </Tooltip>
                    </div>
                )}
                {((agent.agencyLevel === 'Affiliate' || agent.agencyLevel === 'Results' || agent.agencyLevel === 'Cruise holidays') && (agent.isOutsideSales === '0')) && (
                    <div className="m-auto text-base">
                        <Tooltip placement="bottom" padding={5} content="This agent provides outstanding service and expert advice from a TL Network Affiliate Agency">
                        <img src="/images/logo_TL_ntw.png" className="m-auto" alt="TL Network  Logo" loading="lazy" title="This agent provides outstanding service and expert advice from a TL Network Affiliate Agency" />
                        </Tooltip>
                    </div>
                )}
                <div className="grid grid-cols-2 md:grid-cols-1 auto-rows-auto gap-2 w-full md:w-48">
                    {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                        <div className="cta-item">
                            <ButtonInverted
                                disabled={agent.isOffline === '1'}
                                title="EMAIL ME"
                                onClick={() => (agent.isOffline !== '1' ? emailMe(agent) : {})}
                            />
                        </div>
                    )}
                    {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                        <div className="cta-item">
                            <ButtonInverted
                                disabled={agent.isOffline === '1'}
                                title="CALL ME"
                                onClick={() => (agent.isOffline !== '1' ? callMe(agent, () => {
                                    setSelectedAgent(agent);
                                    setIsPhoneFormModalOpen(true);
                                }) : {})}
                            />
                        </div>
                    )}
                    {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
                        <div className="cta-item">
                            <ButtonInverted
                                disabled={agent.isOffline === '1'}
                                title="VIDEO APPT"
                                onClick={() => (agent.isOffline !== '1' ? onVideoAppointment(agent) : {})}
                            />
                        </div>
                    )}
                    <div className="cta-item">
                        <ButtonInverted title="VIEW PROFILE" onClick={() => goToAgentBio(agent)} />
                    </div>
                </div>
            </div>
        </div>
    );

    const agentCardMobile = (agent) => (
        <div className="flex flex-col md:flex-row gap-2 py-4 px-2 mb-6 bg-white rounded-lg shadow-lg justify-between mx-2" key={agent.agentId}>
            <div className="flex flex-col w-full px-2 text-center">
                {agent.preferredAgent === 1 ? (
                    <div className="self-center"><img src="/images/icon-super-agent.svg" height="23" width="100" alt="Super Agent" loading="lazy" /></div>
                ) : ('')}
                <div className="text-red-600 text-xl font-semibold cursor-pointer uppercase" onClick={() => goToAgentBio(agent)}>{`${agent.firstName} ${agent.lastName}`}</div>
                <div className="text-gray-500 text-sm">{`${agent.city}, ${agent.state} `}</div>
                <div className="text-gray-500 text-sm">
                    <AgentAffiliation
                        icAgent={agent.isOutsideSales}
                        agencyLevel={agent.agencyLevel}
                        agencyRecordType={agent.agencyRecordType}
                        icBusinnessName={agent.icBusinessName}
                        agencyName={agent.agencyName}
                        hostAgency={agent.hostAgency}
                        securityLevel={agent.securityLevel}
                    />
                </div>
            </div>
            <div className="md:flex md:space-x-2">
                <div className="item md:w-28 pt-2 text-sm">
                    <img
                        alt={`${agent.firstName} ${agent.lastName}`}
                        src={agent.agentPhotoFileName}
                        className="mx-auto  cursor-pointer agent-image rounded-lg"
                        loading="lazy"
                        onClick={() => goToAgentBio(agent)}
                    />
                </div>
                <div className="item w-full text-xs">
                    {agent.websiteDisplayTitle && (
                        <div className="tl-cyan text-base font-semibold uppercase leading-none mb-1 mt-2 md:mt-0 md:w-48 md:w-full overflow-y-hidden text-center md:text-left">
                            {agent.websiteDisplayTitle}
                        </div>
                    )}
                    {agent.destination && (
                        <div className="mb-1 leading-tight">
                            <span className="mr-1 inline-block font-semibold">Destination Specialties:</span>
                            {agent.destination && agent.destination.map((x, index) => (
                                <span className="mr-1 inline-block" key={`${index}-dest`}>
                                    {selectedDestinationObject?.label?.split('|')[0].trim() === x.localeName || selectedDestinationObject?.label?.split('|')[0].trim() === x.areaName || selectedDestinationObject?.label?.split('|')[0].trim() === x.regionName ? (
                                        <span className="font-semibold italic">{x.locationType === 'Cit' ? x.localeName : (x.locationType === 'Reg' ? x.regionName : x.areaName)}</span>
                                    ) : (
                                            <>
                                                {x.locationType === 'Cit' ? x.localeName : (x.locationType === 'Reg' ? x.regionName : x.areaName)}
                                            </>
                                        )}
                                    {index + 1 < agent.destination.length ? ',' : ''}
                                </span>
                            ))}
                        </div>
                    )}
                    {agent.interest && (
                        <div className="mb-1 leading-tight">
                            <span className="mr-1 inline-block font-semibold">Interest Specialties:</span>
                            {agent.interest && agent.interest.map((x, index) => (
                                <span className="mr-1 inline-block" key={`${index}-int`}>
                                    {selectedInterest === x.interestName ? (
                                        <span className="font-semibold italic">{x.interestName}</span>
                                    ) : x.interestName}
                                    {index + 1 < agent.interest.length ? ',' : ''}
                                </span>
                            ))}

                        </div>
                    )}
                </div>
            </div>
            <div className="flex w-full">
                {agent.topReview && (
                    <div className="flex flex-col gap-1 text-xs  self-end mt-auto w-full pt-2">
                        <div className="font-bold">{`${agent.topReview[0].topReviewName} says`}</div>
                        <div className="italic text-neutral-600 border-neutral-500 quote">{`"${agent.topReview[0].topReview}"`}</div>
                    </div>
                )}
            </div>
            <div className="flex space-x-2">
                <div className="w-1/2 py-2 text-xs text-center self-center">
                    {agent.agentRating && agent.agentRating.length > 0
                        && (
                            <div className="cursor-pointer" onClick={() => goToAgentBio(agent, 'review')}>
                                <div>

                                    <StarRatings
                                        rating={agent.agentRating[0].rating}
                                        starRatedColor="#0B829E"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="20px"
                                        starSpacing="2px"
                                    />

                                </div>
                                <div>
                                    {agent.agentRating[0].totalReviews}
                                    {' '}
                                    reviews
                                </div>
                                <div>
                                    {' '}
                                    {((agent.agentRating[0].recommended / agent.agentRating[0].totalReviews) * 100).toFixed(0)}
                                    % Recommended
                                </div>
                            </div>
                        )}
                    {agent.yearsActive && (
                        <div>
                            {agent.yearsActive}
                            {' '}
                            Years of Experience
                        </div>
                    )}
                </div>
                <div className="w-1/2 py-2 text-sm text-center self-center">
                    {(agent.agencyLevel === 'Enterprise' || agent.agencyLevel === 'Executive' || agent.agencyLevel === 'Professional' || agent.agencyLevel === 'Legacy') && (
                        <div className="m-auto"><img src="/images/logo_TL_itb.png" className="m-auto" alt="TL Network Logo" loading="lazy" title="This agent provides outstanding service and expert advice from a full-service TL Network branded agency" /></div>
                    )}
                    {(agent.agencyLevel === 'Affiliate' || agent.agencyLevel === 'Results' || agent.agencyLevel === 'Cruise holidays') && (
                        <div className="m-auto"><img src="/images/logo_TL_ntw.png" className="m-auto" alt="TL Network Logo" loading="lazy" title="This agent provides outstanding service and expert advice from a TL Network Affiliate Agency" /></div>
                    )}
                </div>
            </div>
            <div className="flex w-full">
                {agent.isOffline === '1' && (
                    <div className="flex justify-center items-center w-full mt-8 text-lg text-grey-900 font-semibold">
                        {agent.isOfflineMsg ? agent.isOfflineMsg : 'I am currently not accepting customers inquiries'}
                    </div>
                )}
            </div>
            <div className="grid grid-cols-1 auto-rows-auto gap-2 w-full md:w-48">
                {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                    <div className="cta-item">
                        <Button
                            disabled={agent.isOffline === '1'}
                            title="EMAIL ME"
                            onClick={() => (agent.isOffline !== '1' ? emailMe(agent) : {})}
                        />
                    </div>
                )}
                {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                    <div className="cta-item">
                        <Button
                            disabled={agent.isOffline === '1'}
                            title="CALL ME"
                            onClick={() => (agent.isOffline !== '1' ? callMe(agent, () => {
                                setSelectedAgent(agent);
                                setIsPhoneFormModalOpen(true);
                            }) : {})}
                        />
                    </div>
                )}
                {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
                    <div className="cta-item">
                        <Button
                            disabled={agent.isOffline === '1'}
                            title="VIDEO APPT"
                            onClick={() => (agent.isOffline !== '1' ? onVideoAppointment(agent) : {})}
                        />
                    </div>
                )}
                <div className="cta-item">
                    <Button title="VIEW PROFILE" onClick={() => goToAgentBio(agent)} />
                </div>
            </div>
        </div>
    );

    function displayAgentCards(agent) {
        if (window.innerWidth < 768) {
            return agentCardMobile(agent);
        }
        return agentCard(agent);
    }

    const hasActiveFilter = () => activeFilters.destination || activeFilters.interest
        || activeFilters.state || activeFilters.agentName || activeFilters.zipCode
        || activeFilters.metro || activeFilters.language || activeFilters.supplier;

    const groupedSpecialities = () => {
        const items = [];
        getSpecialities().map((x) => {
            if (items.some((y) => y.name === x.category)) {
                items.find((y) => y.name === x.category).items.push({
                    value: x.interest,
                    label: x.interest,
                });
            } else {
                items.push({
                    type: 'group',
                    name: x.category,
                    items: [{
                        value: x.interest,
                        label: x.interest,
                    }],
                });
            }

            return true;
        });

        return items;
    };

    const sideFilter = () => (
        <div className="item w-full h-auto px-6">
            <div className="md:flex flex-col">

                <div className="fixed top-16 md:top-0 w-full z-40 bg-blue-dark left-0 right-0 h-full  md:relative md:flex flex-col">
                    <div className="overflow-y-auto  md:overflow-y-hidden h-full pb-20 px-4 md:px-0 bg-light-blue-bar">
                        <div className="md:hidden pt-8 md:pt-0 mx-auto">
                            <Button title={filter ? 'HIDE FILTERS' : 'SHOW FILTERS'} onClick={() => setFilter(!filter)} />
                        </div>
                        <div>
                            <State
                                callback={(type, value) => {
                                    setZipCode();
                                    setSelectedZipCodeTemp();
                                    if (type === 'state') {
                                        if (value === 0) {
                                            setSelectedState();
                                            setSelectedCity();
                                            setSelectedMetro();
                                            setActiveFilters(
                                                {
                                                    ...activeFilters, state: '', metro: '', city: '', zipCode: '',
                                                },
                                            );

                                            return;
                                        }
                                        setSelectedMetro();
                                        setSelectedCity();
                                        setSelectedState(value);
                                        setActiveFilters(
                                            {
                                                ...activeFilters, metro: '', city: '', zipCode: '',
                                            },
                                        );
                                    }

                                    if (type === 'metro') {
                                        if (value === 0) {
                                            setSelectedMetro();
                                            setSelectedCity();
                                            setActiveFilters(
                                                {
                                                    ...activeFilters, metro: '', city: '', zipCode: '',
                                                },
                                            );
                                            return;
                                        }
                                        setActiveFilters(
                                            {
                                                ...activeFilters, city: '', zipCode: '',
                                            },
                                        );
                                        setSelectedCity();
                                        setSelectedMetro(value);
                                    }

                                    if (type === 'city') {
                                        if (value === 0) {
                                            setSelectedCity();
                                            setActiveFilters(
                                                { ...activeFilters, city: '', zipCode: '' },
                                            );

                                            return;
                                        }
                                        setActiveFilters(
                                            { ...activeFilters, zipCode: '' },
                                        );
                                        setSelectedCity(value);
                                    }
                                }}
                                trigger={() => {
                                    if (activeFilters.state !== selectedState || activeFilters.metro !== selectedMetro || activeFilters.city !== selectedCity) { searchViaRoute(); }
                                }}
                                state={selectedState}
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                        {/* <div className="text-center border-b border-blue-200 py-2">{label('Destinations')}</div> */}

                        <div>
                            <Destination
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    if (value === 0) {
                                        setSelectedDestination();
                                        setSelectedDestinationObject(null);
                                        setSelectedDestinationObjectTemp(null);
                                        setActiveFilters(
                                            { ...activeFilters, destination: '' },
                                        );

                                        return;
                                    }
                                    setSelectedDestination(value);
                                    setSelectedDestinationObject({
                                        value: getDestinations
                                            .find((x) => x.destination === value)
                                            .destinationId,
                                        label:
                                            getDestinations
                                                .find((x) => x.destination === value)
                                                .destination,

                                    });
                                }}
                                trigger={() => {
                                    if (activeFilters.destination !== selectedDestination) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                        <div>
                            <Interest
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    setSelectedInterest(value);
                                }}
                                trigger={() => {
                                    if (activeFilters.interest !== selectedInterest) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                        <div>
                            <Supplier
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    setSelectedSupplier(value);
                                }}
                                trigger={() => {
                                    if (activeFilters.supplier !== selectedSupplier) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>
                        <div>
                            <Language
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    setSelectedLanguage(value);
                                }}
                                trigger={() => {
                                    if (activeFilters.language !== selectedLanguage) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );

    /*const displayRelatedSearch = () => (
        <div className="grid md:grid-cols-2 gap-4 mt-4 p-2 md:p-0">
            {selectedState && filterCount() === 1 ? (
                <>
                    <RelatedDestByState
                        state={selectedState}
                        metro={!selectedMetro ? '' : selectedMetro}
                    />
                    <RelatedIntByState
                        state={selectedState}
                        metro={!selectedMetro ? '' : selectedMetro}
                    />
                </>
            ) : ''}
            {selectedInterest && filterCount() === 1 ? (
                <>
                    <RelatedDestByInt
                        interest={selectedInterest}
                    />
                    <RelatedMetrosByInt
                        interest={selectedInterest}
                    />
                </>
            ) : ''}
            {selectedDestinationObject && filterCount() === 1 ? (
                <>
                    <RelatedIntByDest
                        destination={selectedDestinationObject.label}
                        destinationId={selectedDestinationObject.value}
                    />
                    <RelatedMetrosByState
                        state={selectedState}
                        destination={selectedDestinationObject.label}
                        destinationId={selectedDestinationObject.value}
                    />
                </>
            ) : ''}

            {selectedDestinationObject && selectedInterest && filterCount() === 2 && userMetro && (userCountry === 'US' || userCountry === 'CA') ? (
                <>
                    <RelatedDestIntByGeo
                        state={userState}
                        metro={userMetro}
                        interest={selectedInterest}
                        destination={selectedDestinationObject?.label?.split('|')[0].trim()}
                        destinationId={selectedDestinationObject.value}
                    />
                </>
            ) : ''}
        </div>
    );*/

    const displayActiveFilters = () => (
        <div>
            { hasActiveFilter()
                && (
                    <div className="text-sm mx-4 pb-2 border-b border-blue-300">
                        <div className="text-center">
                            <div className="font-semibold text-sm uppercase mb-2">CURRENT FILTERS</div>
                        </div>
                        <div className="flex gap-1 flex-wrap  flex-row md:flex-col">
                            {activeFilters.destination
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setSelectedDestination();

                                            setActiveFilters(
                                                { ...activeFilters, destination: '' },
                                            );
                                            setSelectedDestinationObject(null);
                                            setSelectedDestinationObjectTemp(null);
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Destination:
                                        </div>
                                        <div>
                                            {getDestinationName()}
                                        </div>
                                    </div>
                                )}
                            {(activeFilters.interest)
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setSelectedInterest();
                                            setSelectedInterestTemp();
                                            setActiveFilters(
                                                { ...activeFilters, interest: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Interest:
                                        </div>
                                        <div>
                                            {activeFilters.interest}
                                        </div>
                                    </div>
                                )}
                            {activeFilters.state
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setSelectedState();
                                            setSelectedZipCodeTemp();
                                            setSelectedMetro();
                                            setActiveFilters(
                                                { ...activeFilters, metro: '', state: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Agent State:
                                        </div>
                                        <div>
                                            {getStateName(activeFilters.state)}
                                        </div>
                                    </div>
                                )}

                            {activeFilters.agentName
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setAgentName('');

                                            setActiveFilters(
                                                { ...activeFilters, agentName: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Name:
                                        </div>
                                        <div>
                                            {activeFilters.agentName}
                                        </div>
                                    </div>
                                )}

                            {activeFilters.zipCode
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setZipCode();
                                            setSelectedZipCodeTemp();

                                            setActiveFilters(
                                                { ...activeFilters, zipCode: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Location:
                                        </div>
                                        <div>
                                            {activeFilters.zipCode}
                                        </div>
                                    </div>
                                )}

                            {activeFilters.metro && !activeFilters.city
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setSelectedMetro();
                                            setSelectedCity();
                                            setActiveFilters(
                                                { ...activeFilters, metro: '', city: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Metro:
                                        </div>
                                        <div>
                                            {activeFilters.metro}
                                        </div>
                                    </div>
                                )}

                            {activeFilters.city
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setSelectedCity('');
                                            setActiveFilters(
                                                { ...activeFilters, city: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            City:
                                        </div>
                                        <div>
                                            {activeFilters.city}
                                        </div>
                                    </div>
                                )}

                            {activeFilters.language
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setSelectedLanguage('');
                                            setActiveFilters(
                                                { ...activeFilters, language: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Language:
                                        </div>
                                        <div>
                                            {activeFilters.language}
                                        </div>
                                    </div>
                                )}

                            {activeFilters.supplier
                                && (
                                    <div
                                        className="flex flex-row items-center gap-1"
                                        onClick={() => {
                                            setSelectedSupplier('');
                                            setActiveFilters(
                                                { ...activeFilters, supplier: '' },
                                            );
                                            setIsReady(true);
                                        }}
                                    >
                                        <div>
                                            <IoClose className="text-red-500" />
                                        </div>
                                        <div className="font-bold">
                                            Supplier:
                                        </div>
                                        <div>
                                            {activeFilters.supplier}
                                        </div>
                                    </div>
                                )}

                        </div>

                    </div>
                )}
        </div>
    );

    const searchFilter = () => (
        <div className="item w-full h-auto p-4">
            <div className="text-center ">
                {/* {label('REFINE SEARCH RESULTS')} */}

                <div className="flex flex-col items-center space-y-1 pb-4 border-b border-blue-300">
                    <div className="item w-full h-auto pb-4">
                        {label('Location')}
                        <div>
                            <Input
                                placeholder="Enter Postal Code"
                                value={selectedZipCodeTemp}
                                onChange={(val) => setSelectedZipCodeTemp(val)}
                            />
                        </div>
                    </div>

                    <div className="item w-full h-auto pb-4">
                        <div>
                            {label('Specialty')}
                            <div>
                                <SelectInput
                                    options={[{ label: 'Select a Travel Interest', value: '' }].concat(groupedSpecialities())}
                                    placeholder="Select a travel interest"
                                    onSelected={(val) => {
                                        if (val === '') {
                                            setSelectedInterestTemp('');
                                        } else setSelectedInterestTemp(val);
                                    }}
                                    value={selectedInterestTemp}
                                />

                            </div>
                        </div>
                    </div>

                    <div className="item w-full h-auto pb-4">
                        <div>
                            {label('Destination')}
                            <div>

                                <Select
                                    styles={customSelectStyles}
                                    placeholder="Destination"
                                    className="w-full rounded-full text-xs"
                                    options={[{ label: 'Destination', value: '' }].concat(getDestinations.map((x) => ({
                                        value: x.destinationId,
                                        label: x.destination,
                                    })))}
                                    onChange={(val) => {
                                        if (val.value === '') {
                                            setSelectedDestinationObjectTemp(null);
                                        } else { setSelectedDestinationObjectTemp(val); }
                                    }}
                                    onInputChange={(val) => {
                                        if (val !== '') {
                                            setIsMenuOpen(true);
                                        } else setIsMenuOpen(false);
                                    }}
                                    value={selectedDestinationObjectTemp}
                                    menuIsOpen={isMenuOpen}
                                    filterOption={createFilter(filterConfig)}
                                    noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                                    components={{ DropdownIndicator: () => null }}
                                />

                            </div>
                        </div>
                    </div>

                    <div className="item w-full h-auto pt-6">
                        <Button title="SEARCH" onClick={() => onRefineSearch()} />

                        <div className="md:hidden">
                            <Button title={filter ? 'HIDE FILTERS' : 'SHOW FILTERS'} onClick={() => setFilter(!filter)} />
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );

    const loader = () => (
        <div className="max-w-screen-2x px-4 min-h-screen ">
            <div>
                <div className="py-4 w-1/6 inline-block align-top">
                    <Skeleton count={1} height={1200} />
                </div>
                <div className="w-5/6 inline-block pl-2">
                    <div className="py-4">
                        <Skeleton count={1} height={256} />
                    </div>
                    {Array.apply(null, Array(10)).map((r, i) => (
                        <div className=" mb-10" key={`skel${i}`}>
                            <Skeleton height={195} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const recordAdClick = (adId) => {
        API.sendAdvertClick({
            adId,
            destination: selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : getDestinationName(),
            interest: selectedInterest || activeFilters.interest,
            supplier: selectedSupplier || activeFilters.supplier,
        }).then((response) => {});
    };

    const ad = (index) => {
        let searchTerms = '';
        if (window.location.search.substr(1).length > 0) {
            searchTerms = '&' + window.location.search.substr(1);
        }
        if (agents?.agent.length < 3 && ads.length > 0) {
            return index === agents?.agent.length ? (
                <a
                    href={`${ads[0].adURL.length > 0 ? ads[0].adURL : '/promotions/' + ads[0].promotionId + '?rel=search' + ads[0].adTracking}`}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer mx-4"
                    onClick={() => (recordAdClick(ads[0].adId, searchTerms))}
                >
                    <img className="mx-auto" src={`${ADVERT_IMAGE_URL()}${detectMobile.isDesktop() ? ads[0].adFile : ads[0].adFileMobile}`} alt={ads[0].adName} />
                </a>
            ) : undefined;
        }

        const showAd = index % 3 === 0 || agents?.agent.length < 3;
        if (showAd) {
            const key = index < 3 ? 0 : (index / 3) - 1;
            return key < ads.length ? (
                <a
                    href={`${ads[key].adURL.length > 0 ? ads[key].adURL : '/promotions/' + ads[key].promotionId + '?rel=search' + ads[key].adTracking}`}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer mx-4"
                    onClick={() => (recordAdClick(ads[0].adId, searchTerms))}
                >
                    <img className="mx-auto" src={`${ADVERT_IMAGE_URL()}${detectMobile.isDesktop() ? ads[key].adFile : ads[key].adFileMobile}`} alt={ads[key].adName} />
                </a>
            ) : undefined;
        }
        return undefined;
    };

    const canonical = window.location.href;

    /* used for NoIndex NoFollow for param count greater than 3. Ignores destinationId Param in the count */
    const urlParamMatches = canonical.match(/[a-z\d]+=[a-z\d]+/gi);
    const extraParam = urlParamMatches ? urlParamMatches.find(element => element.includes('destinationId')) : undefined;
    let urlParamCount = urlParamMatches ? urlParamMatches.length : 0;
    if (typeof extraParam !== 'undefined') {
        urlParamCount -= 1;
    }

    return (
        <div className="mx-auto bg-light-blue">
            <Helmet>
                <title>{dynamicTitle}</title>
                <meta name="title" content={dynamicTitle} />
                <meta name="description" content={`Browse reviews for ${agents?.totalAgents} ${dynamicTitle}. Certified specialists from America’s #1 agent network. Find the right agent for your trip.`} />
                <link rel="canonical" href={canonical} />
                {(urlParamCount > 3 || (agents?.totalAgents === '0')) ? (
                    <meta name="robots" content="noindex, nofollow" />
                ) : ''}
            </Helmet>
            <ToastContainer />
            <div className="flex flex-col md:flex-col gap-4 justify-center text-xs">
                {agents ? (
                    <div className="md:flex md:flex-row py-12 mx-auto max-w-screen-2xl">
                        {/*ACTIVE FILTERS*/}
                        <div className="hidden md:flex md:flex-col md:space-y-1">
                            {displayActiveFilters()}
                            {searchFilter()}
                            {sideFilter()}
                        </div>
                        {/*END ACTIVE FILTERS*/}
                        {filter && (
                            <div className="flex md:hidden">
                                {searchFilter()}
                                {sideFilter()}
                            </div>
                        )}

                        <div className={[' text-4xl flex flex-col items-center md:items-start   align-top gap-2 flex-1  min-h-screen mt-4 md:mt-0', filter ? ' hidden-tw md:flex' : ''].join(' ')}>

                            <div className="relative lg:flex mx-auto  max-w-screen-xl flex flex-col w-full py-2 px-4 md:p-4 z-10">
                                <div>
                                    <BreadCrumb links={links} />
                                </div>
                                <div className="flex space-x-2">
                                    <div className="item w-full">
                                        {dynamicTitle && (
                                            <h1 className="text-xl md:text-4xl font-semibold py-4 uppercase tl-cyan">
                                                {dynamicTitle}
                                            </h1>
                                        )}
                                        <div className="text-sm md:text-base font-bold py-2">
                                            There are
                                             {' '}
                                            {agents?.totalAgents}
                                            {' '}
                                            agents matching this search
                                        </div>
                                        <div className="text-sm md:text-base hidden-tw md:flex">These trusted travel agents belong to North America’s #1 Travel Agent Network and can provide custom tailored trips, personalized recommendations, detailed knowledge of destinations and exclusive deals and amenities.</div>
                                    </div>
                                    <div className="item w-1/3 flex text-sm items-center justify-center">
                                        <Tooltip placement="left" padding={5} content="These trusted travel agents belong to North America’s #1 Travel Agent Network and can provide custom tailored trips, personalized recommendations, detailed knowledge of destinations and exclusive deals and amenities.">
                                            <img src="/images/icon-tl-seal.webp" alt="North America's #1 travel agent network" className="m-auto w-auto" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="item w-full relative">
                                        <div className="md:hidden -mx-4 p-2 bg-dark-blue-bar text-white sticky top-0 sticky-filter">
                                            {displayActiveFilters()}
                                            <Button title={filter ? 'HIDE FILTERS' : 'SHOW FILTERS'} onClick={() => setFilter(!filter)} padding="py-1 px-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="md:p-4">
                                <div className={[
                                    'flex flex-col gap-4  w-full'].join(' ')}
                                >
                                    {agents?.agent.map((result, index) => (
                                        <div key={index}>
                                            {displayAgentCards(result)}
                                            {ads.length > 0 && (
                                                <>
                                                    { ad(index + 1)}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                {agents?.agent.length !== parseInt(agents?.totalAgents) && agents?.agent.length !== 0
                                    && agents?.agent.length >= 20 && (
                                        <div className="my-4 justify-center flex flex-col items-center gap-4 w-full md:w-1/3 mx-auto">
                                            {isFetchingAgentMore && <LoadingInline />}
                                            <Button
                                                title="Load more agents"
                                                onClick={() => {
                                                    setCurrentPage(currentPage + 1);
                                                }}
                                                fontSize="uppercase text-lg"
                                                width="max-w-sm"
                                            />

                                        </div>

                                    )}
                                {agents?.totalAgents === '0' ? (
                                    <div className="text-base">
                                        <p className="font-bold text-lg">
                                            Sorry, it doesn&apos;t look like we have any agents matching your exact search.
                                            <br />
                                            Try simplifying your search by selecting one of the options below:
                                        </p>
                                        { selectedDestinationObject ? (
                                            <div>
                                                <a className="tl-red hover:text-black font-bold" href={`/agents?destinationId=${selectedDestinationObject.value}&slctDestination=${encodeURIComponent(selectedDestinationObject.label)}`}>
                                                    {`Travel agents who book travel to ${selectedDestinationObject?.label?.split('|')[0].trim()}`}
                                                </a>
                                            </div>
                                        ) : ''}
                                        { selectedState ? (
                                            <div>
                                                <a className="tl-red hover:text-black font-bold" href={`/agents?slctState=${selectedState}`}>
                                                    {`Travel agents located in ${getStateName(selectedState)}`}
                                                </a>
                                            </div>
                                        ) : ''}
                                        { selectedMetro ? (
                                            <div>
                                                <a className="tl-red hover:text-black font-bold" href={`/agents?slctState=${selectedState}&slctMetro=${encodeURIComponent(selectedMetro)}`}>
                                                    {`Travel agents located in ${selectedMetro} Metro`}
                                                </a>
                                            </div>
                                        ) : ''}
                                        { selectedCity ? (
                                            <div>
                                                <a className="tl-red hover:text-black font-bold" href={`/agents?slctState=${selectedState}${selectedMetro.length > 0 ? `&slctMetro=${encodeURIComponent(selectedMetro)}` : ''}&slctCity=${encodeURIComponent(selectedCity)}`}>
                                                    {`Travel agents located in ${selectedCity}, ${selectedState}`}
                                                </a>
                                            </div>
                                        ) : ''}
                                        { selectedInterest ? (
                                            <div>
                                                <a className="tl-red hover:text-black font-bold" href={`/agents?slctInterest=${encodeURIComponent(selectedInterest)}`}>
                                                    {`${selectedInterest} Travel agents `}
                                                </a>
                                            </div>
                                        ) : ''}
                                        { selectedSupplier ? (
                                            <div>
                                                <a className="tl-red mx-auto p-6 hover:text-black font-bold" href={`/agents?slctSupplier=${encodeURIComponent(selectedSupplier)}`}>
                                                    {`Travel agents working with ${selectedSupplier}`}
                                                </a>
                                            </div>
                                        ) : ''}
                                        <div>
                                            <a className="tl-red hover:text-black font-bold" href="/find_travel_agent">
                                                Restart your search for the perfect travel agent.
                                            </a>
                                        </div>
                                    </div>
                                ) : ''}
                                <div>
                                    {/*displayRelatedSearch()*/}
                                </div>
                            </div>

                        </div>
                    </div>
                ) : loader()}
            </div>

            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen || isAppointmentFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agent={selectedAgent} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agent={selectedAgent}
                            phone={selectedAgent.connect.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}

                    {isAppointmentFormModalOpen && (
                        <AppointmentForm
                            agent={selectedAgent}
                        />
                    )}

                    {isEmergencyFromModalOpen && <EmergencyEmailForm agent={selectedAgent} />}

                </div>
            </Modal>

            {isFetchingAgent() && <Loading />}
        </div>
    );
}

export default Agents;
