/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
import React, {
    useEffect, useState,
} from 'react';
import parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import sanitizeHtml from 'sanitize-html-react';
import { BLOG_IMAGE_URL } from '../utils';
import urlSlug from 'url-slug';

function ArticlesList({
    ArticlesCategory,
    ArticlesReturned,
    ArticlesDisplayTitle,
    ArticleOrder,
    ArticleType,
    ArticleDisplayTeaser,
    DisplayArticleCount,
    }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(ArticlesReturned);
    const [hasMore, setHasMore] = useState(false);

    const getBlogs = () => {
        setLoading(true);
        API.getBlogs({
            rows: currentPage,
            name: ArticlesCategory,
            order: ArticleOrder,
            type: ArticleType,
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
                if (result.data.length === currentPage) {
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };
    useEffect(() => {
        getBlogs();
    }, [currentPage]);
    return (
        <>
            {data.length > 0
                && (
                    <div className="">
                        <ToastContainer />
                        {!loading && (
                            <>
                            {DisplayArticleCount === true ? (
                                <div className="flex w-full mb-2">
                                    Found
                                    {' '}
                                    {data.length}
                                    {' '}
                                Articles
                                </div>
                            ) : null}
                                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                    {data.map((x) => (
                                        <div
                                            className="flex flex-col"
                                            key={x.storyId}
                                        >
                                            <Link to={`/travelbetter/${x.storyId}/${urlSlug(x.title)}?category=${ArticleType}~${ArticlesCategory}`}>
                                                <img
                                                    src={`${BLOG_IMAGE_URL()}${x.thumbnailImage}`}
                                                    className="h-auto block rounded hover:scale-105 transform ease-in-out duration-1000"
                                                    alt=""
                                                />
                                                {' '}
                                                {ArticlesDisplayTitle === true ? (
                                                    <div className="tl-red hover:text-gray-700 text-xl font-semibold mt-4 max-w-md">
                                                        {x.title}
                                                    </div>
                                                ) : null}
                                                {ArticleDisplayTeaser === true ? (
                                                    <div className="text-base tl-black-900  max-w-md pt-4 hidden md:block">
                                                        {x.shortDescription.length > 100 ? parser(`${sanitizeHtml(x.shortDescription.slice(0, 100))}...<span class="mx-2 tl-red uppercase font-bold cursor-pointer hover:text-black">Read more</span><p>`)
                                                            : parser(`${sanitizeHtml(x.shortDescription)}...<span class="mx-2 tl-red uppercase font-bold cursor-pointer hover:text-black">Read more</span><p>`)}
                                                    </div>
                                                ) : null}
                                            </Link>
                                        <div />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                    </div>
                )}
            {loading && <Loading />}
        </>
    );
}

ArticlesList.propTypes = {
    ArticlesCategory: PropTypes.string.isRequired,
    ArticlesReturned: PropTypes.string.isRequired,
    ArticlesDisplayTitle: PropTypes.bool,
    ArticleDisplayTeaser: PropTypes.bool,
    ArticleOrder: PropTypes.string,
    ArticleType: PropTypes.string,
    DisplayArticleCount: PropTypes.bool,
};
ArticlesList.defaultProps = {
    ArticlesDisplayTitle: true,
    ArticleDisplayTeaser: true,
    ArticleOrder: '',
    ArticleType: 'C',
    DisplayArticleCount: false,
};

export default ArticlesList;
