/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import React, {
    useEffect, useState,
} from 'react';
import parser from 'html-react-parser';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import API from '../services/API';
import Loading from './elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import sanitizeHtml from 'sanitize-html-react';
import { PROMO_IMAGE_URL } from '../utils';
import Button from './elements/Button';
import PropTypes from 'prop-types';

function PromoCategory({
    promoType,
    promoTitle,
    introTxt,
    returnRows,
    textColor,
}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const history = useHistory();

    let goToUrl = `/promotion_category?category=${promoType.toLowerCase()}`;
    if ((promoTitle.toLowerCase() === 'specials') || (promoType.toLowerCase() === 'all')) {
        goToUrl = '/promotions';
    }

    const getPromos = () => {
        setLoading(true);
        API.getPromotions({
            promotionType: promoType,
            rows: returnRows,
            displayChannel: 7,
            agencyId: 0,
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getPromos();
    }, ['']);
    return (
        <>
            {data.length > 0
                && (
                    <div className="mx-4 md:mx-20 py-16 text-center">
                    <ToastContainer />
                    <h2 className={`${textColor} uppercase text-3xl`}>
                        {promoTitle}
                    </h2>
                        <p className="md:mx-4 text-lg leading-7 tl-black-900">
                        {introTxt}
                        </p>
                        <div className="content flex items-center py-4 space-x-2 text-sm justify-center">
                                <img
                                src="/images/icon-star-exclusive.png"
                                alt="Exclusive Offer"
                                className="h-6 w-6 mx-2"
                                />
                           Indicates special offer only available when booking with a TL Network agent.
                        </div>
                        {!loading && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                                    {data.map((x) => (
                                        <div
                                            className="flex flex-col items-center cursor-pointer"
                                            onClick={() => history.push(`/promotions/${x.promotionId}?category=${promoType}`)}
                                            key={x.promotionId}
                                        >
                                            <div>
                                                <img
                                                    src={`${PROMO_IMAGE_URL()}/${x.fileName}`}
                                                    className="h-52 object-cover block rounded hover:scale-105 transform ease-in-out duration-1000"
                                                    alt=""
                                                />
                                                {' '}
                                                {x.exclusivePromo === true ? <img src="/images/icon-star-exclusive.png" alt="Exclusive Offer" className="w-6 mx-2  absolute top-2" /> : ''}
                                            </div>
                                            <div className="w-full items-left cursor-pointer text-left">
                                                <div className="tl-black-900 uppercase text-base tracking-wide font-bold mt-2">{x.supplierList.length === 0 ? '' : x.supplierList.length === 1 ? x.supplierList[0].supplierName : 'TL Network Supplier Partners'}</div>
                                                <h3 className="tl-cyan text-xl font-semibold max-w-md">{parser(sanitizeHtml(x.headline))}</h3>
                                                <div className="text-base tl-black-900  max-w-md">
                                                    {x.teaserText.length > 100 ? parser(`${sanitizeHtml(x.teaserText.slice(0, 100))}...<div class="tl-red uppercase font-bold cursor-pointer hover:text-black">Read more &#x25B6;</div><p>`)
                                                        : parser(`${sanitizeHtml(x.teaserText)} <div class="tl-red uppercase font-bold cursor-pointer hover:text-black">Read more &#x25B6;</div><p>`)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            { returnRows < 12 ? (
                                <div className="text-center mx-4 lg:mx-96 md:mx-48 sm:mx-20 my-10 uppercase">
                                    <Button
                                        onClick={() => history.push(goToUrl)}
                                        title={`View All ${promoTitle}`}
                                        fontSize="uppercase"
                                        width="max-w-sm"
                                    />
                                </div>
                            ) : ''}
                            </>
                        )}

                    </div>
                )}
            {data.length === 0 && (
                <div className="mx-4 md:mx-20 py-10 text-center hidden">
                    <h2 className={`${textColor} uppercase text-3xl`}>
                        {promoTitle}
                    </h2>
                    <p className="md:mx-4 text-lg leading-7 tl-black-900">
                        {`Sorry, it doesnt look like we have any ${promoTitle} available at this time`}
                    </p>
                </div>
                )}
            {loading && <Loading />}
        </>
    );
}

PromoCategory.propTypes = {
    promoType: PropTypes.string,
    promoTitle: PropTypes.string,
    introTxt: PropTypes.string,
    returnRows: PropTypes.number,
    textColor: PropTypes.string,
};

PromoCategory.defaultProps = {
    promoType: 'Featured Supplier',
    promoTitle: 'Specials',
    introTxt: '',
    returnRows: 4,
    textColor: 'tl-cyan',
};
export default PromoCategory;
