/* eslint-disable react/prop-types */
/* eslint-disable import/order */
import React, {
    useEffect, useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../components/elements/Loading';
import Hero from '../components/Hero';
import ArticlesList from '../components/ArticlesList';
import ArticlesAuthorList from '../components/ArticlesAuthorList';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';

function BlogAuthors() {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [author, setAuthor] = useState(query.get('author') || 'All');

    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (author === 'All') {
            setLinks([{
                label: 'Home',
                url: '/',
            },
                {
                    label: 'Travel Blog',
                    url: '/travelbetter',
                },
                {
                    label: 'Authors',
                },
            ]);
        } else if (author) {
            setLinks([{
                label: 'Home',
                url: '/',
            },
                {
                    label: 'Travel Blog',
                    url: '/travelbetter',
                },
                {
                    label: 'Authors',
                    url: '/travelbetter_authors',
                },
                {
                    label: author,
                },
            ]);
        }
    }, [author]);

    return (
        <div className=" max-w-screen-2xl mx-auto bg-white">
            <ToastContainer />
            <Helmet>
                <title>
                    Viewing
                    {' '}
                    {author === 'All' ? 'All Author' : author}
                    {' '}
                    Articles | TL Network
                </title>
                <meta name="title" content={`Viewing ${author === 'All' ? 'All Author' : author} Articles | TL Network`} />
                <meta
                    name="description"
                    content={`Viewing articles, news, tips, and ideas written by ${author === 'All' ? 'All Author' : author}. Experts who know it best.`}
                />
            </Helmet>
            <Hero title="Travel Better Authors" imageFile="hero-blog-authors.webp" additionalCSS="-mb-20" />
            <div className="min-h-screen pb-40 md:px-10 px-2">
                <div className="flex flex-col md:gap-10 gap-4">
                    <div className="ml-2">
                        <BreadCrumb links={links} />
                    </div>
                    <div className="flex-col items-center justify-center text-center md:mb-10">
                        <h1 className="tl-cyan uppercase text-4xl tracking-wide">
                            {author === 'All' ? 'All Author' : author}
                            {' '}
                            Articles
                        </h1>
                        <div className="h-1.5 bg-blue-dark w-28 mb-2 mt-6 md:hidden" />

                    </div>
                    <div className="flex flex-col gap-4 items-start max-w-screen-5xl mx-4 md:mx-auto md:-ml-2">
                        {author
                            && (
                                <ArticlesAuthorList
                                Id=""
                                Name={author !== 'All' ? author : ''}
                                />
                            )}
                        {author !== 'All' ? (
                            <ArticlesList
                                ArticlesCategory={author}
                                ArticlesReturned="100"
                                ArticlesDisplayTitle={false}
                                ArticleType="A"
                                DisplayArticleCount
                            />
                        ) : null}
                    </div>
                </div>

            </div>

            {loading && <Loading />}
        </div>
    );
}

export default BlogAuthors;
