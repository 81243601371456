import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';
import Hero from '../components/Hero';
import WhyComponent from '../components/WhyComponent';
import Button from '../components/elements/Button';
import Input from '../components/elements/Input';
import Loading from '../components/elements/Loading';
import SearchBox from '../components/SearchBox';
import useAppStore from '../contexts/useAppStore';
import ListItem from '../components/elements/ListItem';
import {
    getStateName, popularInterest, searchTypes,
} from '../utils';
import BreadCrumb from '../components/BreadCrumb';

import 'react-slidedown/lib/slidedown.css';

function FindATravelAgent() {
    const history = useHistory();
    const [showmetro, setShowmetro] = useState(true);
    const links = [{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a Travel Agent',
    },
    ];
    const {
        isFetchingAgent, setSelectedZipCode,
        setSelectedAgentName, selectedZipCode, selectedAgentName,
        selectedInterest,
        getDestinations,
        popularDestinations,
        getStates,
    } = useAppStore();

    const onSearch = (type) => {
        const filters = [];

        if (type === searchTypes.ZIPCODE) {
            filters.push(`zipCode=${selectedZipCode}`);
        } else if (selectedInterest !== '') {
            filters.push(`txtAgentName=${selectedAgentName}`);
        } else {
            return false;
        }

        history.push(`/agents?${filters.join('&')}`);
        return true;
    };

    const popularDestinationLayout = (popularDestination) => (
        <>

            <div className="text-left my-2">
                <div
                    className="font-semibold text-base cursor-pointer hover:text-gray-800 mt-4"
                    onClick={() => (getDestinations.some((x) => x.destinationId === parseInt(popularDestination.id)) ? history.push(`/agents?destinationId=${popularDestination.id}&slctDestination=${getDestinations.find((x) => x.destinationId === parseInt(popularDestination.id)).destination}`) : {})}
                >
                    {popularDestination.location}

                </div>
                {popularDestination.destination.map((y) => (
                    <div
                        className="hover:text-red-500 text-base"
                        key={y.id}
                        onClick={() => (getDestinations.some((x) => x.destinationId === parseInt(y.id)) ? history.push(`/agents?destinationId=${y.id}&slctDestination=${getDestinations.find((x) => x.destinationId === parseInt(y.id)).destination}`) : {})}
                    >

                        <ListItem
                            title={y.location}
                            isClickable
                        />
                    </div>
                ))}
            </div>
        </>
    );

    return (
        <>
            <Helmet>
                <title>Find a Travel Agent | TL Network</title>
                <meta name="title" content="TL Network" />
                <meta name="description" content="Search for a Travel Agent by state, or by travel interest specialty or by destination expertise." />
            </Helmet>
            <Hero title="Find the perfect travel agent for you" imageFile="hero-fata.webp" />
            <div className=" max-w-screen-2xl mx-auto">

                <div className="">

                    <div className="-mt-44 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                        <SearchBox />
                    </div>
                </div>

                <div className="max-w-screen-2xl mx-auto">
                    <section className="flex flex-col justify-center pt-20 text-center mx-auto gap-10 px-4 md:px-10">

                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">FIND A TRAVEL AGENT</h2>
                            <div className="flex flex-col md:flex-row  justify-center gap-10 md:gap-40 md:px-10 px-1">
                                <div className="flex flex-col justify-center w-full">
                                    <h3 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900">Postal Code</h3>
                                    <div className="text-lg leading-7 tl-black-900">Enter a Postal code below to find the agents nearest to that location.</div>
                                    <div className="mt-2 flex flex-col md:flex-row gap-4 justify-center">
                                        <div className="flex md:w-1/2">
                                            <Input
                                                value={selectedZipCode}
                                                onChange={(val) => setSelectedZipCode(val)}
                                                placeholder="Enter Postal Code"
                                            />
                                        </div>
                                        <Button title="SEARCH" onClick={() => onSearch(searchTypes.ZIPCODE)} width="w-full md:w-40" />
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center w-full">

                                    <h3 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900">Agent name</h3>
                                    <div className="text-lg leading-7 tl-black-900">Enter a first and last name below to find a specific travel agent.</div>
                                    <div className="mt-2 flex flex-col md:flex-row gap-4 justify-center">
                                        <div className="flex md:w-1/2">
                                            <Input
                                                value={selectedAgentName}
                                                onChange={(val) => setSelectedAgentName(val)}
                                                placeholder="Enter Agent Name"
                                            />
                                        </div>
                                        <Button title="SEARCH" onClick={() => onSearch(searchTypes.AGENTNAME)} width="w-full md:w-40" />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10">

                                <h3 className="uppercase text-xl mb-2 font-semibold tracking-wider tl-black-900" id="metroArea">
                                    Popular Metro Areas
                                </h3>
                                <div className="text-lg leading-7 tl-black-900">Select a Province below and press the dropdown arrow to see popular metro areas.</div>

                                <SlideDown className="my-dropdown-slidedown text-base">
                                    {showmetro
                                        && (
                                            <div className="flex flex-col md:flex-row gap-10 text-left my-4">
                                                <div className="grid grid-cols-2  md:grid-cols-6 gap-1 md:px-10 px-1 tl-black-900">
                                                    {getStates().map((x) => (
                                                        <div key={x.state}>
                                                            <div className="w-56 px-4" onClick={() => history.push(`/agents?slctState=${x.state}`)} />
                                                            <div>
                                                                <ListItem
                                                                    closed
                                                                    title={getStateName(x.state)}
                                                                    isClickable
                                                                    onParentClicked={() => history.push(`/agents?slctState=${x.state}`)}
                                                                >
                                                                    {
                                                                        x.metroArea.length > 0
                                                                            ? x.metroArea.map((metro) => (
                                                                                <div
                                                                                    key={metro.metroRegion}
                                                                                    className="hover:text-red-900 cursor-pointer"
                                                                                    onClick={() => history.push(`/agents?slctMetro=${metro.metroRegion}&slctState=${x.state}`)}
                                                                                >
                                                                                    {metro.metroRegion}
                                                                                </div>
                                                                            ))
                                                                            : null
                                                                    }
                                                                </ListItem>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        )}
                                </SlideDown>

                            </div>
                    </section>

                    <section className="flex flex-col justify-center pt-10 pb-20 mt-20 text-center mx-auto gap-10 bg-light-blue  px-4 md:px-10">
                        <div>
                            <h2 className="tl-cyan uppercase text-4xl tracking-wide">
                                FIND A TRAVEL SPECIALIST
                            </h2>
                            <div className="text-lg leading-7 tl-black-900">Find a professional travel agent who specializes in a destination or travel interest.</div>

                        </div>

                        <div className="flex flex-col md:flex-row justify-center space-x-2 -mt-4">
                            <div className="item w-full h-auto">

                                <h3 className="uppercase text-xl font-semibold tracking-wide tl-black-900 mb-2 md:mb-1" id="destination">Popular destinations</h3>
                                {popularDestinations.length > 0 && (
                                    <>
                                        <div className="flex justify-center space-x-2 mb-8 md:mb-0">
                                            <div className="item w-full h-auto">
                                                {popularDestinationLayout(popularDestinations[1])}
                                                {popularDestinationLayout(popularDestinations[0])}
                                            </div>
                                            <div className="item w-full h-auto">
                                                {popularDestinationLayout(popularDestinations[2])}
                                                {popularDestinationLayout(popularDestinations[3])}
                                            </div>
                                            <div className="item w-full h-auto">
                                                {popularDestinationLayout(popularDestinations[4])}
                                                {popularDestinationLayout(popularDestinations[5])}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="item w-full h-auto">
                                <h3 className="uppercase text-xl font-semibold tracking-wide tl-black-900 mb-2 md:mb-4" id="interest">Popular Interests</h3>
                                <div className="grid grid-cols-2  md:grid-cols-3 gap-1 text-xs">
                                    {popularInterest.map((x) => (
                                        <div
                                            className="hover:text-red-500 md:w-56 px-4"
                                            key={x}
                                            onClick={() => history.push(`/agents?slctInterest=${encodeURIComponent(x)}`)}
                                        >
                                            <ListItem
                                                title={x}
                                                isClickable
                                            />
                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    </section>
                </div>

                <div className="">
                    <WhyComponent
                        displayStats="hidden"
                        barColor="bg-dark-blue-bar"
                        inactiveColor="text-white"
                    />
                </div>
            </div>
            {isFetchingAgent() && <Loading />}
        </>
    );
}

export default FindATravelAgent;
